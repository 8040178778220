import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyConverter } from "../../features/helper";
import {
  getSelectedPost,
  makeCurrentPostDisliked,
  makeCurrentPostLiked,
  selectedPost,
} from "../../features/post/postSlice";
import "./DetailsPage.css";
import FullDetailCars from "./PostSpecifications/Cars/FullDetailCars";
import { logEvent } from "firebase/analytics"

import { CallNumber } from "@ionic-native/call-number";
import { WHATSAPP_MSG } from "../../config/constants";
import { NAV_FULL_DETAIL } from "../../providers/NavigationProvider";
import {
  selectCurrentLocation,
  selectPreviousLocation,
  setCurrentLocation,
} from "../../features/navigation/navigationSlice";
import { Plugins } from "@capacitor/core";
import { frontend } from "../../config/urls";
import moment from "moment";
import { isPlatform } from "@ionic/react";
import {
  MAKE_CALL,
  SEND_MESSAGE,
  SHARE,
  VIEW_POST_DETAIL,
} from "../../config/events";
import { ACTION_CLICK, ACTION_VIEW } from "../../config/actions";
import { Carousel } from "react-responsive-carousel";
import {
  Avatar,
  Breadcrumbs,
  Button,
  createStyles,
  Dialog,
  Hidden,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  apartment_rent,
  apartment_sale,
  audio_speakers,
  bikes,
  cameras,
  cars,
  commercial_rent,
  commercial_sale,
  computers,
  daily,
  house_rent,
  house_sale,
  land_rent,
  land_sale,
  mobiles,
  mobile_parts,
  monthly,
  other_electronics,
  room_rent,
  tab,
  tv,
} from "../../constants/categories";
import FullDetailApartments from "./PostSpecifications/Houses/FulldetailApartments";
import FsLightbox from "fslightbox-react";
import { resetPosition, selectPosition, setPosition, setScroll } from "../../features/scroll/scrollSlice";
import { selectCategories } from "../../features/tags/tagsSlice";
import { url } from "inspector";
import FullDetailCarsMobile from "./PostSpecifications/CarsMobile/FullDetailCarsMobile";
import FullDetailBikesMobile from "./PostSpecifications/BikesMobile/FullDetailBikesMobile";
import FullDetailMobilesMobile from "./PostSpecifications/MobilesMobile/FullDetailMobilesMobile";
import FullDetailElectronicsMobile from "./PostSpecifications/MobilesMobile/FullDetailElectronicsMobile";
import FullDetailApartmentsMobile from "./PostSpecifications/HousesMobile/FulldetailApartmentsMobile";
import FullDetailHousesMobile from "./PostSpecifications/HousesMobile/FullDetailHousesMobile";
import FullDetailCommercialsMobile from "./PostSpecifications/CommercialMobile/FullDetailLCommercialsMobile";
import FullDetailLandsMobile from "./PostSpecifications/LandsMobile/FullDetailLandsMobile";
import FullDetailHousesRentMobile from "./PostSpecifications/HousesMobile/FullDetailHousesRent";
import FullDetailRoomRentMobile from "./PostSpecifications/RoomsMobile/FullDetailRoomRentMobile";
import DetailsSideBar from "../../components/DetailsSideBar/DetailsSideBar";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { translateTime } from "../../language/sTime";
import { useLocation, useNavigate } from "react-router-dom";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shareBtn: {
      padding: "0",
      margin: "0",
      verticalAlign: "top",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    shareMenu: {
      marginTop: "56px",
      marginLeft: "19px",
      "& .MuiMenu-list": {
        backgroundColor: "#f5f6f8",
      },
      "& .MuiListItem-root": {
        padding: "5px 11px",
      },

      "& .MuiPaper-elevation8": {
        boxShadow: "0px 2px 0px #DDDDDD",
      },
    },
    status: {
      "& .carousel-status": {
        left: 20,
        top: "375px",
        width: "42px",
        height: "22px",
        borderRadius: "11px",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        color: "#000000",
        textShadow: "none",
        fontWeight: 700,
        opacity: 0.4,
      },
      "& .control-dots .dot": {
        width: "6px",
        height: "6px",
      },
      "& .control-dots": {
        marginBottom: "13px",
      },
    },
    editbtn: {
      color: "#00AEFF",
      textTransform: 'capitalize',
      '& .MuiButton-startIcon': {
        display: 'block'
      },
      '& .MuiButton-label': {
        alignItems: 'center'
      },
      [theme.breakpoints.down('sm')]:{
        // width:100,
        height:35,

      }
    },
  })
);


const { Share } = Plugins;


function DetailsPage(props: any) {
  const classes = useStyles();

  const FullDetailBikes = React.lazy(
    () => import("./PostSpecifications/Bikes/FullDetailBikes")
  );
  const FullDetailMobiles = React.lazy(
    () => import("./PostSpecifications/Mobiles/FullDetailMobiles")
  );
  const FullDetailLands = React.lazy(
    () => import("./PostSpecifications/Lands/FullDetailLands")
  );
  const FullDetailCommercials = React.lazy(
    () => import("./PostSpecifications/Commercial/FullDetailLCommercials")
  );
  const FullDetailHouses = React.lazy(
    () => import("./PostSpecifications/Houses/FullDetailHouses")
  );
  const FullDetailHousesRent = React.lazy(
    () => import("./PostSpecifications/Houses/FullDetailHousesRent")
  );
  const FullDetailElectronics = React.lazy(
    () => import("./PostSpecifications/Mobiles/FullDetailElectronics")
  );
  const FullDetailRoomRent = React.lazy(
    () => import("./PostSpecifications/Rooms/FullDetailRoomRent")
  );
  const LikeButton = React.lazy(
    () => import("../../components/LikeButton/LikeButton")
  );

  const dispatch = useDispatch();
  const item = useSelector(selectedPost);
  const placeholder = "assets/images/placeholder.png";
  const navigate = useNavigate();
  const prevLocation = useSelector(selectPreviousLocation);
  const topref: any = useRef();
  const [sortedImages, setSortedImages] = useState<any>([]);
  const categories = useSelector(selectCategories);
  const scrollContent = document.getElementById("scrollable-root");
  // const scrollPosition = useSelector(selectPosition);
  // const position=useSelector(selectCurrentLocation);
  const { search, hash, pathname } = useLocation()



  const [toggler, settoggler] = useState(false);
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  const detailPageRequest = (hash: string) => {
    dispatch(getSelectedPost(hash));
  };

  const [shareBox, setShareBox] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!prevLocation || prevLocation == "/start") {
      // console.log(pathname.split('/')[3]);

      // const postId = props.match?.params.id;
      const postId = pathname.split('/')[3];
      if (postId !== undefined) detailPageRequest(postId);
    }
  }, [prevLocation]);

  useEffect(() => {
    if (Object.keys(item).length === 0) return;
    const ci: any = item.desktop_images[item.cover_image];
    const others: Array<string> = item.desktop_images.filter(
      (img: any, index: number) => index != item.cover_image
    );
    setSortedImages([ci, ...others]);
  }, [item]);

  useEffect(() => {

    dispatch(setCurrentLocation(NAV_FULL_DETAIL));
    scrollContent?.scrollTo(0, 0);
    logEvent(analytics, VIEW_POST_DETAIL, {
      action: ACTION_VIEW,
      platform: "DESKTOP",
    });
  }, []);


  function doPromote(seo: string) {
    // history.go(`/payment/${seo}`);
    window.location.href = (`/promote/${seo}`);
    // navigate(`/promote/${seo}`);
  }

  const pageGoBack = () => {
    if (prevLocation) navigate(-1);
    else navigate("/home", { replace: true });
  };

  // window.addEventListener('popstate', function (event) {
  //   // // The popstate event is fired each time when the current history entry changes.

  //   // var r = confirm("You pressed a Back button! Are you sure?!");

  //   // if (r == true) {
  //   //   // Call Back button programmatically as per user confirmation.
  //   //   history.back();
  //   //   // Uncomment below line to redirect to the previous page instead.
  //   //   // window.location = document.referrer // Note: IE11 is not supporting this.
  //   // } else {
  //   //   // Stay on the current page.
  //   //   navigateState(null, null, window.location.pathname);
  //   // }

  //   // navigateState(null, null, window.location.pathname);
  //   pageGoBack();
  // }, false);

  const handleCallClick = () => {
    CallNumber.callNumber(item.mobile, false)
      .then()
      .catch(() => {
        window.open(
          isPlatform("android")
            ? `tel:${item.mobile}`
            : `telprompt:${item.mobile}`,
          "_self"
        );
      });

    logEvent(analytics, MAKE_CALL, { action: ACTION_CLICK });
  };

  const handleWhatsappChatOpen = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${item.whatsapp}&text=${WHATSAPP_MSG}`
    );

    logEvent(analytics, SEND_MESSAGE, { action: ACTION_CLICK });
  };

  const sharOnWhatsApp = () => {

    const options = {
      title: item.title,
      text: "Click on the link to see the advertisement",
      url:
        frontend +
        "/" +
        item.main_category.slug +
        "/" +
        item.sub_category.slug +
        "/" +
        item.hash,
      dialogTitle: "Share with friends",
    };
    // console.log(options.url);
    // window.open(`https://api.whatsapp.com/send?text=${options.url}`);
    window.open(`https://api.whatsapp.com/send?text=` + options.url);
    logEvent(analytics, SHARE, { action: ACTION_CLICK, platform: "DESKTOP" });
    return false;
  };

  const shareOnFacebook = () => {
    const options = {
      title: item.title,
      text: "Click on the link to see the advertisement",
      url:
        frontend +
        "/" +
        item.main_category.slug +
        "/" +
        item.sub_category.slug +
        "/" +
        item.hash,
      dialogTitle: "Share with friends",
    };
    // window.open(`https://facebook.com/share/sharer.php?u=${options.url}`);
    window.open('http://www.facebook.com/sharer/sharer.php?u=' + options.url, 'sharer', 'toolbar=0,status=0,width=626,height=436');
    logEvent(analytics, SHARE, { action: ACTION_CLICK, platform: "DESKTOP" });
    return false;
  };

  const handleShare = () => {
    const options = {
      title: item.title,
      text: "Click on the link to see the advertisement",
      url:
        frontend +
        item.main_category.slug +
        "/" +
        item.sub_category.slug +
        "/" +
        item.hash,
      dialogTitle: "Share with friends",
    };
    const nav: any = window.navigator;

    if (nav.share) {
      Share.share(options)
        .then()
        .catch(() => {
          nav.share(options).then().catch();
        });
    }
  };

  const handleLike = () => {
    dispatch(makeCurrentPostLiked(props.item));
  };

  const handleDislike = () => {
    dispatch(makeCurrentPostDisliked(props.item));
  };

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShareBox(event.currentTarget);
  };

  const handleShareClose = () => {
    setShareBox(null);
  };


  const renderSwitch = () => {
    switch (item?.sub_category?.name) {
      case cars:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailCars item={item}></FullDetailCars>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailCarsMobile item={item}></FullDetailCarsMobile>
            </Hidden>
          </Suspense>
        );
      case bikes:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailBikes item={item}></FullDetailBikes>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailBikesMobile item={item}></FullDetailBikesMobile>

            </Hidden>
          </Suspense>
        );

      case mobiles:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailMobiles item={item}></FullDetailMobiles>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailMobilesMobile item={item}></FullDetailMobilesMobile>

            </Hidden>
          </Suspense>
        );
      case mobile_parts:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailElectronics item={item}></FullDetailElectronics>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>

              <FullDetailElectronicsMobile item={item}></FullDetailElectronicsMobile>
            </Hidden>
          </Suspense>
        );
      case tab:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailElectronics item={item}></FullDetailElectronics>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailElectronicsMobile item={item}></FullDetailElectronicsMobile>

            </Hidden>
          </Suspense>
        );
      case audio_speakers:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailElectronics item={item}></FullDetailElectronics>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>

              <FullDetailElectronicsMobile item={item}></FullDetailElectronicsMobile>
            </Hidden>
          </Suspense>
        );
      case tv:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailElectronics item={item}></FullDetailElectronics>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>

              <FullDetailElectronicsMobile item={item}></FullDetailElectronicsMobile>
            </Hidden>
          </Suspense>
        );
      case computers:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailElectronics item={item}></FullDetailElectronics>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>

              <FullDetailElectronicsMobile item={item}></FullDetailElectronicsMobile>
            </Hidden>
          </Suspense>
        );
      case cameras:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailElectronics item={item}></FullDetailElectronics>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailElectronicsMobile item={item}></FullDetailElectronicsMobile>

            </Hidden>
          </Suspense>
        );
      case other_electronics:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailElectronics item={item}></FullDetailElectronics>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailElectronicsMobile item={item}></FullDetailElectronicsMobile>
            </Hidden>
          </Suspense>
        );

      case apartment_sale:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailApartments item={item}></FullDetailApartments>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailApartmentsMobile item={item}></FullDetailApartmentsMobile>
            </Hidden>
          </Suspense>
        );
      case house_sale:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailHouses item={item}></FullDetailHouses>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailHousesMobile item={item}></FullDetailHousesMobile>
            </Hidden>
          </Suspense>
        );
      case commercial_sale:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailCommercials item={item}></FullDetailCommercials>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailCommercialsMobile item={item}></FullDetailCommercialsMobile>
            </Hidden>
          </Suspense>
        );
      case land_sale:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailLands item={item}></FullDetailLands>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailLandsMobile item={item}></FullDetailLandsMobile>
            </Hidden>
          </Suspense>
        );

      case apartment_rent:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailHousesRent item={item}></FullDetailHousesRent>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>

              <FullDetailHousesRentMobile item={item}></FullDetailHousesRentMobile>
            </Hidden>
          </Suspense>
        );
      case land_rent:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailLands item={item}></FullDetailLands>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailLandsMobile item={item}></FullDetailLandsMobile>
            </Hidden>
          </Suspense>
        );
      case house_rent:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailHousesRent item={item}></FullDetailHousesRent>

            </Hidden>
            <Hidden lgUp={true} mdUp={true}>

              <FullDetailHousesRentMobile item={item}></FullDetailHousesRentMobile>
            </Hidden>
          </Suspense>
        );
      case daily:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailHouses item={item}></FullDetailHouses>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailHousesMobile item={item}></FullDetailHousesMobile>
            </Hidden>
          </Suspense>
        );
      case monthly:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailHouses item={item}></FullDetailHouses>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailHousesMobile item={item}></FullDetailHousesMobile>
            </Hidden>
          </Suspense>
        );
      case room_rent:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailRoomRent item={item}></FullDetailRoomRent>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailRoomRentMobile item={item}></FullDetailRoomRentMobile>
            </Hidden>
          </Suspense>
        );
      case commercial_rent:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Hidden xsDown={true} smDown={true}>
              <FullDetailCommercials item={item}></FullDetailCommercials>
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              <FullDetailCommercialsMobile item={item}></FullDetailCommercialsMobile>
            </Hidden>
          </Suspense>
        );
      default:
        return;
    }
  };

  const checkNav = (cat: any) => {
    dispatch(resetPosition({}));
    const name = cat.replaceAll("-", " ");
    navigate(`/search?c1=${encodeURIComponent(name)}`);
  };

  const handleTagClick = (tag: any) => {
    dispatch(resetPosition({}));
    const cat = tag.name.toLowerCase();
    const res = categories.filter((c1: any) =>
      c1.sub_categories.map((sc: any) => sc.name).includes(tag.name)
    );
    navigate(
      `/search?c1=${res[0]?.name?.toLowerCase()}&c2=${encodeURIComponent(cat)}`
    );
  };

  const renderField = (type: any): any => {
    const values = item.tags?.filter((tag: any) => tag.types.includes(type));
    // console.log(values);

    if (values?.length <= 1)
      return (
        <>
          {values[0].slug == "not-applicable" ? (
            <span id="txt-location" key={values[0].id}>
              (na)
            </span>
          ) : (
            <span id="txt-location-details" key={values[0].id}>
              {
                (
                  values[0].slug.charAt(0).toUpperCase() +
                  values[0].slug.slice(1).toLowerCase()
                ).split("-")[0]
              }
              &nbsp;&nbsp; › &nbsp;&nbsp;
              {
                (
                  values[0].slug.charAt(0).toUpperCase() +
                  values[0].slug.slice(1).toLowerCase()
                ).split("-")[1]
              }
            </span>
          )}
        </>
      );
    return values
      ?.filter((tag: any) => tag.name !== "(NA)")
      .map((object: any) => (
        <span id="txt-location-details" key={object.id}>
          {object.name.charAt(0).toUpperCase() +
            object.name.slice(1).toLowerCase()}
        </span>
      ));
  };

  const isDeal = () => {
    return item.discounted_price != 0 && item.discounted_price < item.price;
  };
  const getSubVariableName = (name: string) => {
    if (name != undefined) {
      // console.log(name.toUpperCase().replace(/ /g, "_").replace(/-/g, "_").replace('&', 'AND'))
      return name.toUpperCase().replace(/ /g, "_").replace(/-/g, "_").replace('&', 'AND');
    } else {
      return '';
    }
  }

  return (
    <>
      {item ? (
        <div >
          <div>
            <Hidden xsDown={true} smDown={true}>
              <div id="breadcrum">

                <Breadcrumbs
                  separator={
                    <NavigateNextIcon
                      fontSize="small"
                      style={{ width: "10px", height: "10px", marginTop: 5 }}
                    />
                  }
                  aria-label="breadcrumb"
                >
                  <Link
                    id="breadcrum-text"
                    underline="none"
                    onClick={() => checkNav(item?.main_category?.slug)}
                    style={{ cursor: "pointer", padding: 0, margin: 0 }}
                  >
                    {/* {item?.main_category?.slug} */}
                    {/* {lan[getSubVariableName(item?.main_category?.slug)]} */}
                    {(item?.main_category?.slug != undefined ? lan[getSubVariableName(item?.main_category?.slug)] : item?.main_category?.slug)}

                  </Link>
                  <Link
                    id="breadcrum-text"
                    underline="none"
                    onClick={() => handleTagClick(item?.sub_category)}
                    style={{ cursor: "pointer", padding: 0, margin: 0 }}
                  >
                    {/* {item?.sub_category?.slug} */}

                    {(item?.sub_category?.slug != undefined ? lan[getSubVariableName(item?.sub_category?.slug)] : item?.sub_category?.slug)}
                  </Link>
                  <Link
                    id="breadcrum-text-title"
                    underline="none"
                    style={{ padding: 0, margin: 0 }}
                  >
                    {item?.title}
                  </Link>
                </Breadcrumbs>
              </div>
              <div
                style={{ width: "100%", borderBottom: "2px solid #DDDDDD" }}
              ></div>
            </Hidden>
            <section id="detail-header" ref={topref}>
              <div id="fd-detail-title">{item.title}</div>
            </section>
            <div id="fd-location-time">
              <div id="fd-icon-location">
                <img
                  src="assets/icon/icon-location-pin.svg"
                  id="icon-location"
                  style={{ marginRight: '5px' }}
                />
                {/* { renderField("Location")  */
                  //  item?.tags
                  //  ?.filter((tag: any) => tag.types.includes("Location"))
                  //  .filter((tag: any) => tag.name !== "(NA)")
                  //  .map((location: any) => (
                  //    <span id="txt-location" key={location.id}>
                  //      {location.name.charAt(0).toUpperCase() +
                  //        location.name.slice(1).toLowerCase()}
                  //    </span>
                  //  ))

                }


                {

                  (item.specs !== undefined && item.specs.City !== undefined && item.specs.City !== null ? <span id="txt-location">{item.specs.City.charAt(0).toUpperCase() +
                    item.specs.City.slice(1).toLowerCase()}</span> : null)
                }



                <span style={{ color: "#DDDDDD", fontSize: "12px" }}>
                  {" "}
                  | &nbsp;&nbsp;
                </span>
                <span id="fd-timestamp-details">
                  {/* {moment(item.created_at).fromNow()} */}
                  {translateTime(moment(item.created_at).fromNow(), getLanguage())}
                </span>
                <div>
                  <div style={{ display: 'flex', marginTop: '5px' }}>
                    {(item.isJump === "1" ?
                      <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                        <Avatar variant='circle' src="../../assets/addOn/icon-jump-up.svg" style={{ width: '22px', height: '22px' }}></Avatar>
                      </div> : null)
                    }
                    {(item.istop === "1" ?
                      <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                        <Avatar variant='circle' src="../../assets/addOn/icon_super-ad.png" style={{ width: '22px', height: '22px' }}></Avatar>
                      </div> : null)
                    }
                    {(item.isquick === "1" ?
                      <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                        <Avatar variant='rounded' src="../../assets/addOn/icon_quick-sale-lable.png" style={{ width: '22px', height: '22px' }}></Avatar>
                      </div> : null)
                    }
                    {(item.isSpot === "FEATURE" ?
                      <div style={{ flexBasis: 1, marginLeft: "10px" }}>
                        <Avatar variant='circle' src="../../assets/addOn/icon_featured-ad.png" style={{ width: '22px', height: '22px' }}></Avatar>
                      </div> : null)

                    }

                  </div>
                </div>
              </div>
            </div>



            {/* desktop view */}
            <Hidden xsDown={true} smDown={true}>
              <div id="slides-fulldetail">

                {item?.desktop_images?.length == 1 ? (
                  item?.desktop_images?.map((image: string, index: number) => (
                    <>


                      <div
                        key={index}
                        className="slide-fulldetail"
                        onClick={() => settoggler(!toggler)}
                      >
                        <div style={{ position: "relative" }}>
                          {image != null && image !== "" && image != undefined ?
                            <img src="../../assets/images/saruwata-watermark.png" style={{ backgroundColor: "#EBF5F6", width: "100%", height: "100%", backgroundImage: "url(" + image + ")", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }} />
                            : null}
                          {/* <img
                        key={index}
                        src={image}
                        className="slider-image-fulldetail"
                        style={{ border: "1px solid #DDDDDD" }}
                      ></img> */}
                        </div>

                      </div>


                    </>
                  ))
                ) : (
                  <Carousel
                    swipeable={true}
                    showThumbs={true}
                    showStatus={false}
                    autoPlay={true}
                    useKeyboardArrows={true}
                    emulateTouch={true}
                    thumbWidth={114}
                    width={719}
                    stopOnHover={true}

                    className={classes.status}
                  >
                    {sortedImages?.map((image: string, index: number) => (
                      <div
                        key={index}
                        // style={{ backgroundImage: 'url(' + image + ')' }}
                        className="slide-fulldetail"
                        onClick={() => settoggler(!toggler)}
                        style={{ position: "relative" }}
                      >

                        {/* <img
                        src={image}
                        className="slider-image-fulldetail"
                        style={{ border: "1px solid #DDDDDD" }}
                      />
                      <img src="../../assets/images/saruwata-watermark.png" style={{ zIndex: 100, position: "absolute", top: 0 }} /> */}
                        <img src="../../assets/images/saruwata-watermark.png" style={{ backgroundColor: "#EBF5F6", width: "100%", height: "100%", backgroundImage: "url(" + image + ")", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }} />

                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </Hidden>




            {/* mobile view */}
            <Hidden lgUp={true} mdUp={true}>
              <div id="slides-fulldetail-mobile">

                {item?.desktop_images?.length == 1 ? (
                  item?.desktop_images?.map((image: string, index: number) => (
                    <>


                      <div
                        key={index}
                        className="slide-fulldetail-mobile"
                        onClick={() => settoggler(!toggler)}
                      >
                        <div>
                          {image != null && image !== "" && image != undefined ?
                            <img src="../../assets/images/saruwata-watermark.png" style={{ backgroundColor: "#EBF5F6", width: "100%", height: "100%", backgroundImage: "url(" + image + ")", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }} />
                            : null}
                          {/* <img
                        key={index}
                        src={image}
                        className="slider-image-fulldetail"
                        style={{ border: "1px solid #DDDDDD" }}
                      ></img> */}
                        </div>

                      </div>


                    </>
                  ))
                ) : (
                  <Carousel
                    swipeable={true}
                    showThumbs={true}
                    showStatus={false}
                    autoPlay={true}
                    useKeyboardArrows={true}
                    emulateTouch={true}
                    // thumbWidth={30}
                    width={'100%'}
                    stopOnHover={true}

                    className={classes.status}
                  >
                    {sortedImages?.map((image: string, index: number) => (
                      <div
                        key={index}
                        // style={{ backgroundImage: 'url(' + image + ')' }}
                        className="slide-fulldetail-mobile"
                        onClick={() => settoggler(!toggler)}
                        style={{ position: "relative" }}
                      >

                        {/* <img
                        src={image}
                        className="slider-image-fulldetail"
                        style={{ border: "1px solid #DDDDDD" }}
                      />
                      <img src="../../assets/images/saruwata-watermark.png" style={{ zIndex: 100, position: "absolute", top: 0 }} /> */}
                        <img src="../../assets/images/saruwata-watermark.png" style={{ backgroundColor: "#EBF5F6", width: "100%", height: "100%", backgroundImage: "url(" + image + ")", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }} />

                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </Hidden>


            {/* <div
              onClick={() => settoggler(!toggler)}
              style={{
                position: "absolute",
                width: "34px",
                height: "34px",
                backgroundColor: "#EBEEF1",
                borderRadius: "6px",
                opacity: "0.5",
                top: "504px",
                right: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="assets/icon/icon-fullscreen.svg"
                style={{ width: "22px", height: "22px" }}
              ></img>
            </div> */}
            {item?.desktop_images?.length > 1 ? (
              <div style={{ height: "90px" }}></div>
            ) : <div style={{ height: "20px" }}></div>}

            <div style={{ background: "#fff" }}>
              <div id="icons-container">
                <div id="left-icons">
                  <div id="icon-value">
                    <span className="icon-text">{item.like_count}</span>
                    {/* Likes */}
                    {lan.LIKES}
                  </div>
                  <div id="icon-value">
                    <span className="icon-text">{item.click_count}</span>
                    {/* Views */}
                    {lan.VIEWS}
                  </div>
                </div>

                <div id="center-spred">
                  <Button
                    variant="text"


                    // size="small"
                    // style={{ marginLeft: 10, color: (status !== "Sold" ? `#FFFFFF` : `#DDDDDD`), backgroundColor: (status !== "Sold" ? `#0080FF` : `#FFFFFF`), borderColor: (status !== "Sold" ? `#0080FF` : `#DDDDDD`), boxShadow: "none" }}
                    style={{ color: `#ffff`, backgroundColor: '#0080FF', boxShadow: "none"}}

                    className={classes.editbtn}
                    onClick={() => doPromote(item.hash)}
                  >
                    Promote This Ad
                  </Button>


                </div>

                <div>
                  <Suspense fallback={<span>...</span>}>
                    <LikeButton
                      item={item}
                      onLike={handleLike}
                      onDislike={handleDislike}
                    />
                  </Suspense>
                  <Button
                    aria-haspopup="true"
                    onClick={handleShareClick}
                    className={classes.shareBtn}
                    disableRipple
                    disableFocusRipple
                  >
                    <img
                      className="share-icon"
                      src="assets/icon/icon-share.svg"
                    />
                  </Button>


                </div>
              </div>

              <div id="top-container">
                <div id="detailbox-price">
                  {item.price != 0 ? (
                    <div
                      color="primary"
                      className="price"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span className="ru-in-price" style={{ fontSize: "20px" }}>{lan.RS}. </span>{" "}
                      {currencyConverter().format(
                        isDeal() ? item.discounted_price : item.price
                      )}
                      {item?.sub_category?.name == land_sale ||
                        item?.sub_category?.name == land_rent ? (
                        <span style={{ fontSize: "14px", fontWeight: 600 }}>
                          {" "}
                          {(item.specs.Land_Area_Perch == undefined) ? lan.PER_TOTAL : lan.PER_PERCH}
                        </span>
                      ) : null}
                      {isDeal() ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginLeft: "15px" }}>
                            <span className="discount-price-txt">{lan.RS}. </span>
                            <span className="discount-price">{item.price}</span>
                          </div>
                          <div style={{ marginLeft: "15px" }}>
                            <span className="discount-percentage">
                              -{item.discount_percentage}%
                            </span>
                          </div>
                          <div style={{ marginLeft: "5px" }}>
                            <img
                              src="assets/icon/icon-sale.svg"
                              style={{
                                width: 36,
                                height: 27,
                                marginBottom: "-14px",
                              }}
                            ></img>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div color="primary" className="ask-price">
                      {lan.ASK_FOR_PRICE}
                    </div>
                  )}
                </div>
              </div>

              <div
                id="spec-container"
                style={{
                  display:
                    item?.main_category?.name == "CLASSIFIEDS" ? "none" : "",
                  padding: 0
                }}
              >
                {renderSwitch()}

              </div>

              <div id="fd-description-title" style={{ marginTop: "10px", marginBottom: "10px" }}>
                {/* Description */}
                {lan.DESCRIPTION}
              </div>
              {/* <section id="fd-section-descrption-normal"> */}
              <div id="fd-description" style={{ backgroundColor: "#F5F6F8", padding: "10px", color: "#5A5A5A", marginTop: "10px", whiteSpace: "pre-wrap" }}>
                {/* {item.description} */}
                {item.description
                  ?.split(/\r|\n/)
                  .map((param: any, index: number) => (
                    <p key={index} className="description-param">
                      {" "}
                      {param}
                    </p>
                  ))}
              </div>
              {/* </section> */}
            </div>
          </div>
        </div>
      ) : null}

      <Menu
        id="simple-menu"
        anchorEl={shareBox}
        keepMounted
        open={Boolean(shareBox)}
        onClose={handleShareClose}
        className={classes.shareMenu}
      >
        <MenuItem>
          {/* <img
            src="assets/icon/icon-facebook-share.svg"
            style={{ width: "24px", height: "24px" }}
            onClick={shareOnFacebook}
          ></img> */}
          <a rel="nofollow" href="http://www.facebook.com/share.php?u=<;url>" onClick={shareOnFacebook} target="_blank">
            <img
              src="assets/icon/icon-facebook-share.svg"
              style={{ width: "24px", height: "24px" }}
            ></img>
          </a>
        </MenuItem>
        <MenuItem>

          <img
            src="assets/icon/icon-whatsapp-share.svg"
            style={{ width: "24px", height: "24px" }}
            onClick={sharOnWhatsApp}
          ></img>
          {/* <a href="https://api.whatsapp.com/send?text=" title="Share On Whatsapp" onClick={sharOnWhatsApp}>
            <img
              src="assets/icon/icon-whatsapp-share.svg"
              style={{ width: "24px", height: "24px" }}
            ></img> 
          </a>*/}
        </MenuItem>
      </Menu>

      {/* <FsLightbox
        toggler={toggler}
        sources={item.desktop_images}
        disableLocalStorage={true}
        type="image"
      /> */}



      <Hidden mdUp={true} lgUp={true}>
        <DetailsSideBar />
      </Hidden>

    </>
  );
}

export default DetailsPage;
