import { Accordion, Avatar, Button, Card, CardActionArea, CardContent, CardMedia, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedCategories } from "../../features/addPost/addPostSlice";
import { postSpecs, selectNewPosts } from "../../features/post/postSlice";
import Link from '@material-ui/core/Link';
import { payxx, payxx_Ad_Payement_Membership } from "../../config/func";
import { useLocation } from "react-router-dom";
import { getAddOnCodes, selectAddOns, selectAmount, selectBoostAd, selectBundlePack, selectClientRef, selectFeatureAd, selectImg, selectJumpAd, selectPrice, selectQuickSell, selectSeotag, selectTitle } from "../features/addOnServices";
import axios from "axios";
import { ZERO_PAYMENT } from "../../config/urls";
import { toast } from "react-toastify";
import QuickSell from "../AddOn/QuickSell";
import { selectIsMember, selectJumpAdValues, selectReferenceMember, selectUhmId, selectVoucherFreeAmount } from "../AddOn/AddOnValues";
import { setAddOnCodesBoostAd, setAddOnCodesBundlePack, setAddOnCodesFeatureAd, setAddOnCodesJumpAd, setAddOnCodesQuickSell, setBoostAd, setBundlePack, setFeatureAd, setJumpAd, setQuickSell } from "../features/addOnServices";
import { useNavigate } from "react-router-dom";





const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxWidth: 752,
            backgroundColor: "#F9F9F9",
            padding: "10px 20px"

        },
        cardroot: {
            maxWidth: 345,
        },
        media: {
            height: 140,
        },
        wrap: {
            backgroundColor: "#F9F9F9",
        },
        title: {
            margin: theme.spacing(4, 0, 2),
        },
        text: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineClamp: 4,
            boxOrient: 'vertical',
            display: '-webkit-box',
            '& .MuiTypography-body1': {
                fontSize: "12px",
                fontWeight: 600,
                color: "#000000"
            },
            '& .MuiTypography-body2': {
                fontSize: "14px",
                fontWeight: 400,
                color: "#333333"
            }
        },
        multiline: {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiTypography-body1': {
                fontSize: "12px",
                fontWeight: 600,
                color: "#000000",
            },
            '& .MuiTypography-body2': {
                fontSize: "14px",
                fontWeight: 400,
                color: "#333333"
            }
        },
        multilinewrapper: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiListItemText-multiline': {
                marginTop: '0px !important'
            }
        },
        header: {
            '& .MuiTypography-body1': {
                fontSize: "13px",
                color: "#000000",
                marginLeft: "4px"
            },
        },
        listItemText: {
            display: 'flex',
            alignItems: 'center'
        },
        listIcon: {
            minWidth: 36
        },
        cardHeader: {
            fontSize: 15,
            fontWeight: 'bold'
        },
        cardTitle: {
            fontSize: 16,
        },
        pos: {
            marginBottom: 12,
        },
        cardTotalAmount: {
            fontSize: 14,
            fontWeight: 'bold'
        },
        cardTotalAmountPrice: {
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: "right"

        },
        rounded: {
            color: '#fff',
            // backgroundColor: '#C0C0C0'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '90%',
            marginInlineStart: '15px'
        },
    }),
);



const PaymentOverviewSideBar = () => {

    const seoTag = useLocation().pathname.split("/")[2];
    const state = useLocation().pathname.split("/")[1];

    const classes = useStyles();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const img = useSelector(selectImg);
    const title = useSelector(selectTitle);
    const price = useSelector(selectPrice);
    const clientRef = useSelector(selectClientRef);
    const amount = parseInt(useSelector(selectAmount));

    var jumpAd = useSelector(selectJumpAd);
    var featureAd = useSelector(selectFeatureAd);
    var quickSell = useSelector(selectQuickSell);
    var boostAd = useSelector(selectBoostAd);
    var bundlePack = useSelector(selectBundlePack);
    var ac = useSelector(getAddOnCodes);
    var voucherFreeAmount = useSelector(selectVoucherFreeAmount);
    // var ref = useSelector(selectClientRef);
    var uhmid = useSelector(selectUhmId);
    var isMember = useSelector(selectIsMember);

    const [paymentMethod, setPaymentMethod] = useState('');
    const [open, setOpen] = useState(false);
    const JumpAdValues = useSelector(selectJumpAdValues);
    const isJumpAdValueNull = () => {
        if (JumpAdValues.length > 0) {
            return false;
        }

        return true;
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);

    };

    var displayAmount = 0;
    var total = 0;
    var totalMember = 0;


    useEffect(() => {
        popUp();

    }, []);

    const isAmountAvailable = () => {
        if (amount > 0) {
            // displayAmount = parseInt(amount.toString().slice(0, -2));
            displayAmount = parseInt(amount.toString());

        } else {
            displayAmount = 0;
        }
        return true;
    }


    const isBundlePackAvailable = () => {
        if (parseInt(bundlePack) > 0) {

            return true;
        } else {

            return false;
        }
    }
    const isJumpAdAvailable = () => {
        if (parseInt(jumpAd) > 0) {

            return true;
        } else {

            return false;
        }
    }
    const isBoostAdAvailable = () => {
        if (parseInt(boostAd) > 0) {

            return true;
        } else {

            return false;
        }
    }
    const isFeaturedAdAvailable = () => {
        if (parseInt(featureAd) > 0) {

            return true;
        } else {

            return false;
        }
    }
    const isQuickSellAvailable = () => {
        if (parseInt(quickSell) > 0) {

            return true;
        } else {

            return false;
        }
    }
    const isMembershipCustomer = () => {
        if (isMember) {
            return true;
        }
        return false;
    }
    const setTotal = () => {
        total = displayAmount + parseInt(bundlePack) + boostAd + quickSell + jumpAd + featureAd;
        // total = 0 + parseInt(bundlePack) + boostAd + quickSell + jumpAd + featureAd;
        // console.log(">>>>>" + total);

        return <Grid item xs={4} className={classes.cardTotalAmountPrice} style={{ color: "#0080FF" }}>
            Rs.{total}
        </Grid>;
    }
    const setTotalMember = () => {
     
        
        totalMember = parseInt(bundlePack) + boostAd + quickSell + jumpAd + featureAd;

        return totalMember;
        // total = 0 + parseInt(bundlePack) + boostAd + quickSell + jumpAd + featureAd;
        // console.log(">>>>>" + total);
        // return <Grid item xs={4} className={classes.cardTotalAmountPrice} style={{ color: "#0080FF" }}>
        //     Rs.{totalMember}
        // </Grid>;
    }

    const setPaymentTotal = () => {

        return total * 100;

    }
    const setPaymentTotalMembership = () => {

        return totalMember * 100;

    }

    function getAC() {

        return ac;
    }


    function zeroSubmit(amount: number, clientRef: string, comment: string, seoTag: string) {
        axios.post(ZERO_PAYMENT, {
            postId: "",
            seoTag: seoTag,
            ref: clientRef,
            amount: amount,
            payIp: '',
            addons: []
        }
        ).then(value => {
            if (value.data === "Done") {
                navigate("/home");
                toast.success("Your Ad is Under Review", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        }).catch(error => console.log(error));
    }

    const popUp = () => {


        if (displayAmount == 0 && state !== "promote") {
            setOpen(true)
        }


    }



    function zeroSubmitWithAdOns(amount: number, clientRef: string, comment: string, seoTag: string) {
        var addOnCodes = [];

        if (ac.bundlePack != '') {
            addOnCodes.push(ac.bundlePack);
        }
        if (ac.jumpAd != '') {
            addOnCodes.push(ac.jumpAd);
        }
        if (ac.boostAd != '') {
            addOnCodes.push(ac.boostAd);
        }
        if (ac.featureAd != '') {
            addOnCodes.push(ac.featureAd);
        }
        if (ac.quickSell != '') {
            addOnCodes.push(ac.quickSell);
        }
        axios.post(ZERO_PAYMENT, {
            postId: "",
            seoTag: seoTag,
            ref: clientRef,
            amount: amount,
            payIp: '',
            addons: addOnCodes
        }
        ).then(value => {
            if (value.data === "Done") {
                // console.log("d>>" + value.data);
                navigate("/home");
                toast.success("Your Ad is Under Review", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        }).catch(error => console.log(error));
    }
    const returnToMyAds = () => {
        dispatch(setJumpAd(0));
        dispatch(setBoostAd(0));
        dispatch(setFeatureAd(0));
        dispatch(setQuickSell(0));
        dispatch(setBundlePack(0));

        dispatch(setAddOnCodesJumpAd(''));
        dispatch(setAddOnCodesBoostAd(''));
        dispatch(setAddOnCodesBundlePack(''));
        dispatch(setAddOnCodesFeatureAd(''));
        dispatch(setAddOnCodesQuickSell(''));
        window.location.href = ('/myads');
    }
    return (
        <div>
            <div style={{ height: "39px", borderBottom: "1px solid #0080FF" }}></div>


            {/* 
            <Prompt

                message={`You've not applied any promotion. Are you sure you want to Cancel ?`}


            /> */}
            <Grid container>

                <Grid item xs={12}>
                    <div className={classes.wrap}>
                        <List style={{ backgroundColor: "#F9F9F9", padding: "0" }}>

                            <div className={classes.root} >

                                <Typography className={classes.cardHeader} >
                                    Payment Summery
                                </Typography>

                                <Grid container style={{ marginTop: '10px' }}>
                                    <Grid item xs={8}>
                                        Payment
                                    </Grid>

                                    {isAmountAvailable() ?
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                            Rs.{displayAmount}
                                        </Grid> : null}

                                </Grid>
                                {
                                    (isBundlePackAvailable() ?
                                        <Grid container style={{ marginTop: '10px' }}>

                                            <Grid item xs={8}>
                                                Bundle Pack
                                            </Grid>
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                Rs.{bundlePack}
                                            </Grid>
                                        </Grid>
                                        : null)


                                }

                                {
                                    (isJumpAdAvailable() ?
                                        <Grid container style={{ marginTop: '10px' }}>

                                            <Grid item xs={8}>
                                                Jump Ad
                                            </Grid>
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                Rs.{jumpAd}
                                            </Grid>
                                        </Grid>
                                        : null)


                                }
                                {
                                    (isBoostAdAvailable() ?
                                        <Grid container style={{ marginTop: '10px' }}>

                                            <Grid item xs={8}>
                                                Boost Ad
                                            </Grid>
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                Rs.{boostAd}
                                            </Grid>
                                        </Grid>
                                        : null)


                                }
                                {
                                    (isQuickSellAvailable() ?
                                        <Grid container style={{ marginTop: '10px' }}>

                                            <Grid item xs={8}>
                                                Quick Sell
                                            </Grid>
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                Rs.{quickSell}
                                            </Grid>
                                        </Grid>
                                        : null)


                                }
                                {
                                    (isFeaturedAdAvailable() ?
                                        <Grid container style={{ marginTop: '10px' }}>

                                            <Grid item xs={8}>
                                                Featured Ad
                                            </Grid>
                                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                Rs.{featureAd}
                                            </Grid>
                                        </Grid>
                                        : null)


                                }
                                <br></br>
                                <Divider></Divider>

                                <Grid container style={{ marginTop: '10px', paddingBottom: "10px", borderBottom: "0.5px solid #DDDDDD" }}>
                                    <Grid item xs={8} className={classes.cardTotalAmount}>
                                        Total
                                    </Grid>
                                    {
                                        setTotal()

                                    }


                                </Grid>

                                {(isMembershipCustomer() ? <>


                                    <Grid container style={{ marginTop: '10px', paddingBottom: "10px", borderBottom: "0.5px solid #DDDDDD" }}>
                                        <Grid item xs={8} className={classes.cardTotalAmount}>
                                            {/* Voucher FreeAmount */}
                                            Available Voucher Balance
                                        </Grid>
                                        <Grid item xs={4} className={classes.cardTotalAmountPrice} style={{ color: "#0080FF" }}>

                                            Rs.{voucherFreeAmount - setTotalMember()}
                                        </Grid>


                                    </Grid>

                                </> : null)}

                            </div>
                            <br></br>
                            <Card className={classes.cardroot} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>

                                {(total == 0 ?
                                    // <CardContent>
                                    //     <Typography className={classes.cardHeader} >
                                    //         Select a payment method
                                    //     </Typography>
                                    //     <Grid container direction="row" style={{ marginTop: '10px' }} spacing={2}>


                                    //         <Grid item >
                                    //             <Avatar variant="rounded" className={classes.rounded}
                                    //                 alt='Visa'
                                    //                 src='../../assets/addOn/icon-visa.svg'


                                    //             >

                                    //             </Avatar>
                                    //         </Grid>

                                    //         <Grid item >
                                    //             <Avatar variant="rounded" className={classes.rounded}
                                    //                 alt='MasterCard'
                                    //                 src='../../assets/addOn/icon-mastercard.svg'


                                    //             >

                                    //             </Avatar>
                                    //         </Grid>

                                    //         <Grid item >
                                    //             <Avatar variant="rounded" className={classes.rounded}
                                    //                 alt='Amex'
                                    //                 src='../../assets/addOn/icon-amex.svg'
                                    //                 hidden
                                    //             >

                                    //             </Avatar>
                                    //         </Grid>


                                    //         <Grid item>
                                    //             <Avatar variant="rounded" className={classes.rounded}
                                    //                 alt='Cargils Bank'
                                    //                 src='../../assets/addOn/icon-cargils.png'
                                    //                 hidden
                                    //             >

                                    //             </Avatar>
                                    //         </Grid>
                                    //         <Grid item>
                                    //             <Avatar variant="rounded" className={classes.rounded}
                                    //                 alt='Bank'
                                    //                 src='../../assets/addOn/icon-bank.svg'
                                    //                 hidden
                                    //             >

                                    //             </Avatar>
                                    //         </Grid>
                                    //     </Grid>

                                    // </CardContent>
                                    null :

                                    (!isMembershipCustomer() ?
                                        <CardContent>
                                            <Typography className={classes.cardHeader} >
                                                Select a payment method
                                            </Typography>
                                            <Grid container direction="row" style={{ marginTop: '10px' }} spacing={2}>

                                                {

                                                    (paymentMethod === 'Visa' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                        <Avatar variant="rounded" className={classes.rounded}
                                                            alt='Visa'
                                                            src='../../assets/addOn/icon-visa.svg'
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPaymentMethod('Visa');

                                                            }}
                                                        >

                                                        </Avatar>
                                                    </Grid> : <Grid item >
                                                        <Avatar variant="rounded" className={classes.rounded}
                                                            alt='Visa'
                                                            src='../../assets/addOn/icon-visa.svg'
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPaymentMethod('Visa');

                                                            }}
                                                        >

                                                        </Avatar>
                                                    </Grid>)
                                                }

                                                {

                                                    (paymentMethod === 'Master' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                        <Avatar variant="rounded" className={classes.rounded}
                                                            alt='MasterCard'
                                                            src='../../assets/addOn/icon-mastercard.svg'
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPaymentMethod('Master');

                                                            }}
                                                        >

                                                        </Avatar>
                                                    </Grid> : <Grid item >
                                                        <Avatar variant="rounded" className={classes.rounded}
                                                            alt='MasterCard'
                                                            src='../../assets/addOn/icon-mastercard.svg'
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPaymentMethod('Master');

                                                            }}
                                                        >

                                                        </Avatar>
                                                    </Grid>)
                                                }
                                                {/* {

                                                    (paymentMethod === 'Amex' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                        <Avatar variant="rounded" className={classes.rounded}
                                                            alt='Amex'
                                                            src='../../assets/addOn/icon-amex.svg'

                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPaymentMethod('Amex');

                                                            }}
                                                        >

                                                        </Avatar>
                                                    </Grid> : <Grid item >
                                                        <Avatar variant="rounded" className={classes.rounded}
                                                            alt='Amex'

                                                            src='../../assets/addOn/icon-amex.svg'

                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setPaymentMethod('Amex');

                                                            }}
                                                        >

                                                        </Avatar>
                                                    </Grid>)
                                                } */}
                                                {

                                                    (paymentMethod === 'Cargils' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                        <Grid item>
                                                            <Avatar variant="square" className={classes.rounded}
                                                                alt='Cargils Bank'

                                                                src='../../assets/addOn/icon-cargils.png'
                                                                onClick={() => {
                                                                    setPaymentMethod('Cargils');

                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                            </Avatar>
                                                        </Grid>
                                                    </Grid> : <Grid item >
                                                        <Grid item>
                                                            <Avatar variant="square" className={classes.rounded}
                                                                alt='Cargils Bank'

                                                                src='../../assets/addOn/icon-cargils.png'
                                                                onClick={() => {
                                                                    setPaymentMethod('Cargils');

                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                            </Avatar>
                                                        </Grid>
                                                    </Grid>)
                                                }
                                                {

                                                    (paymentMethod === 'Bank' ? <Grid item style={{ border: "0.5px solid #ADD8E6" }}>
                                                        <Grid item>
                                                            <Avatar variant="rounded" className={classes.rounded}
                                                                alt='Bank'

                                                                src='../../assets/addOn/icon-bank.png'
                                                                onClick={() => {
                                                                    setPaymentMethod('Bank');

                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                            </Avatar>
                                                        </Grid>
                                                    </Grid> : <Grid item >
                                                        <Grid item>
                                                            <Avatar variant="rounded" className={classes.rounded}
                                                                alt='Bank'

                                                                src='../../assets/addOn/icon-bank.png'
                                                                onClick={() => {
                                                                    setPaymentMethod('Bank');

                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                            </Avatar>
                                                        </Grid>
                                                    </Grid>)
                                                }



                                            </Grid>

                                        </CardContent>
                                        : null)
                                )}


                            </Card>
                            {/* <Card className={classes.cardroot} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                                <CardContent>
                                    <Typography className={classes.cardHeader} >
                                        Pay by voucher
                                    </Typography>
                                </CardContent>
                                <TextField
                                    label="Voucher Code"
                                    defaultValue=""
                                    className={classes.textField}
                                    // helperText="Type your voucher code"
                                    margin="dense"
                                    variant="outlined"
                                />

                                <Link href="#" style={{ marginInlineStart: '15px' }}>
                                    Check balance or combine voucher
                                    {(total > 0)}
                                </Link> */}

                            {(isMembershipCustomer() ?

                                <Typography align='center'>
                                    {

                                        (displayAmount > 0 ?
                                            (state == "promote" ?
                                                <Button disabled variant="contained" color="primary" style={{ width: '88%' }}>
                                                    Promote this ad
                                                </Button> :
                                                <Button disabled variant="contained" color="primary" style={{ width: '88%' }}>
                                                    Post This Ad
                                                </Button>)

                                            :
                                            (state == "promote" ?
                                                <Button disabled={(voucherFreeAmount > 0 && total > 0 ? false : true)} variant="contained" onClick={() => payxx_Ad_Payement_Membership(setPaymentTotalMembership(), clientRef, 'Saruwata.lk Transaction', getAC(), seoTag, uhmid, totalMember, 0, returnToMyAds)} color="primary" style={{ width: '88%' }}>
                                                    Promote your ad
                                                </Button>
                                                :
                                                <Button variant="contained" onClick={() => payxx_Ad_Payement_Membership(setPaymentTotalMembership(), clientRef, 'Saruwata.lk Transaction', getAC(), seoTag, uhmid, totalMember, 0, returnToMyAds)} color="primary" style={{ width: '88%' }}>
                                                    Post This Ad
                                                </Button>
                                            )

                                        )


                                    }

                                </Typography>



                                :

                                <Typography align='center'>
                                    {

                                        (total > 0 ?
                                            (paymentMethod === 'Visa' || paymentMethod === 'Master' ?

                                                <Button disabled={(paymentMethod === 'Visa' || paymentMethod === 'Master' ? false : true)} variant="contained" onClick={() => payxx(setPaymentTotal(), clientRef, 'Saruwata.lk Transaction', 711111100, getAC(), seoTag)} color="primary" style={{ width: '88%' }}>
                                                   {(state == 'promote' ?"Promote This Ad": "Post This Ad")} 
                                                </Button> :
                                                (paymentMethod === 'Bank' ?
                                                    <Button disabled={(paymentMethod === 'Bank' ? false : true)} onClick={() => navigate('/diectPayment/bankDeposit?price=' + total + '&ref=' + clientRef)} variant="contained" color="primary" style={{ width: '88%' }}>
                                                        Direct Bank Deposit
                                                    </Button> :
                                                    <Button disabled variant="contained" color="primary" style={{ width: '88%' }}>
                                                        {(state == "promote" ?
                                                            "Promote your ad"
                                                            :

                                                            "Post This Ad"
                                                        )}
                                                    </Button>
                                                )


                                            )
                                            :

                                            <Button variant="contained" onClick={() => zeroSubmit(total, clientRef, 'Saruwata.lk Transaction', seoTag)} color="primary" style={{ width: '88%' }}>
                                                Post This Ad
                                            </Button>
                                        )
                                        // (total == 1 || total == 2 || total == 3 ?

                                        //     <Button variant="contained" onClick={() => zeroSubmitWithAdOns(0, clientRef, 'Saruwata.lk Transaction', seoTag)} color="primary" style={{ width: '88%' }} >
                                        //         Finish
                                        //     </Button>
                                        //     :
                                        //     <Button variant="contained" onClick={() => zeroSubmit(total, clientRef, 'Saruwata.lk Transaction', seoTag)} color="primary" style={{ width: '88%' }}>
                                        //         Finish
                                        //     </Button>
                                        // )

                                    }

                                </Typography>
                            )}
                            <br />

                            <Typography style={{ textAlign: 'left', fontSize: '12px', padding: "10px 20px" }}>
                                By confirming payment, you are approving our <span style={{ color: "#0080FF" }}>Terms & Conditions</span> and acknowledging our <span style={{ color: "#0080FF" }}>Privacy Policy</span>.Your payment information and details will remain confidential.</Typography>
                            <Divider variant="middle"></Divider>
                            <Typography style={{ textAlign: 'left', fontSize: '12px', padding: "20px 20px" }}>
                                For inquiries please contact our hotline by dialing +94 117 455 955 from any network or write to us on <span style={{ color: "#0080FF" }}>service @saruwata.lk</span>
                            </Typography>

                            <br></br>

                        </List>
                    </div>
                </Grid >

            </Grid >


            <br></br>
            <br></br>

            <Dialog
                open={open}
                hidden={(isJumpAdValueNull() ? true : false)}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleClose()
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ zIndex: 2000 }}
            >
                <DialogTitle id="alert-dialog-title">  Thank you for using Saruwata.lk</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        Your ad is being reviewed by our agents to get more quality leads. Please use the below options to Complete your Ad.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(isMembershipCustomer() ?

                        <>
                            {

                                (displayAmount > 0 ?

                                    <Button disabled variant="contained" color="primary" >
                                        Publish your ad
                                    </Button>
                                    :
                                    <Button variant="contained" onClick={() => payxx_Ad_Payement_Membership(setPaymentTotalMembership(), clientRef, 'Saruwata.lk Transaction', getAC(), seoTag, uhmid, totalMember, 0, returnToMyAds)} color="primary" >
                                        Publish your ad
                                    </Button>
                                )


                            }

                        </>



                        :
                        <>
                            {

                                (total > 0 ?
                                    null
                                    :

                                    <Button variant="contained" onClick={() => zeroSubmit(total, clientRef, 'Saruwata.lk Transaction', seoTag)} color="primary" >
                                        Publish your ad
                                    </Button>
                                )


                            }

                        </>

                    )}
                    <Button onClick={handleClose} variant="contained" color="secondary" autoFocus>
                        {
                            (total > 0 ? "Proceed to Pay" : " Promote Your Ad")
                        }

                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    );
}
export default PaymentOverviewSideBar;