import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import "./TitleBar.css";
import {
  Avatar,
  ButtonGroup,
  Container,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import {
  BEHAVE_LOGIN_REQUIRED,
  NavigationContext,
  NAV_ACCOUNT,
  NAV_LIKES,
  NAV_MYADS,
} from "../../providers/NavigationProvider";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../providers/UserProvider";
import { signOut } from "../../features/session/firebaseSlice";
import { logEvent } from "firebase/analytics"
import { LOGOUT } from "../../config/events";
import { ACTION_CLICK } from "../../config/actions";
import { selectAuthFreeze } from "../../features/session/sessionSlice";
import AddPostButton from "../AddPost/AddPostButton/AddPostButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { setAmount, setBoostAd, setFeatureAd, setJumpAd, setPrice, setQuickSell } from "../../a_components/features/addOnServices";
import { setJumpAdValues } from "../../a_components/AddOn/AddOnValues";
import { selectLanguage, setLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { useNavigate } from "react-router-dom";
import { analytics } from "../..";
// import * as lan from "../../language/sinhala";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    width: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: "1280px",
        padding: 0,
      },
    },
    padding: {
      paddingTop: 2,
    },
    menuitem: {
      height: "41px",
      transition: "all 0.5s ease",
      fontSize: "14px",
      color: "#5A5A5A",
      "&:hover": {
        color: "#0080FF",
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: 10,
      border: "1px solid #fff",
      boxSizing: "border-box",
      background: "#fff",
    },
    logo: {
      cursor: "pointer",
      
    },
    menuIcon: {
      minWidth: 30,
    },
    menu: {
      "& .MuiList-padding": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiPaper-elevation8": {
        boxShadow: "0px 0px 6px #0000001A",
      },
    },
  })
);
// var lan = import("../../language/sinhala");

export default function TitleBar() {
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = () => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  // var lan = require('../../language/' + (selectedLanguage == 'si' ? 'sinhala' : 'english'));


  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const authFreeze = useSelector(selectAuthFreeze);
  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));

  const handleLogin = () => {
    dispatch(setCurrentBehavior(BEHAVE_LOGIN_REQUIRED));
  };

  const handleProfileMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };




  const handleProfileMenuItemClick = (item: string) => {
    if (item === "profile") navigate("/profile");
    else if (item === "likes") navigate("/likes");
    else if (item === "myads") {
      // window.location.href = ("/myads");
      navigate("/myads");

      // dispatch(setSeotag(""));
      // dispatch(setTitle(""));
      dispatch(setPrice(0));
      // dispatch(setImg([]));
      dispatch(setAmount(0));
      dispatch(setJumpAd(0));
      dispatch(setFeatureAd(0));
      dispatch(setBoostAd(0));
      dispatch(setQuickSell(0));
      // dispatch(setClientRef(''));
      dispatch(setJumpAdValues([]));

    };
    setAnchorEl(null);
  };

  const handleNewAd = (user: any) => {
    if (user == null || user.email === "") {
      handleLogin();
      return;
    }

    navigate("/add-post");
  };

  const handleLogoClick = () => {
    navigate("/start");
  };

  const onSignOut = () => {
    navigate("/home");
    logEvent(analytics, LOGOUT, { action: ACTION_CLICK });
  };

  return (
    <AppBar position="static" id="titlebar-wrapper" style={{ padding: "0", position: 'sticky', top: 0 }}>
      <Container maxWidth="lg" className={classes.width}>

        <UserContext.Consumer>
          {(user: any) => (
            <Toolbar id="nav-toolbar" className={classes.padding}>

              <div onClick={handleLogoClick} className={classes.logo}>

                <img src="assets/logo/saruwata-logo.png" id="logo"></img>
              </div>


              <div style={{ display: "flex" }}>
                {smallViewPort && (
                  <div style={{ display: "flex" }}>
                    {/* <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "14px",
                        cursor: "default",
                        marginRight: "4px",
                      }}
                    >
                      <img src="assets/icon/icon-mobile-app.svg"></img>
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          marginLeft: "10px",
                          marginRight: "50px"
                        }}
                      >
                        {/* {lan.DOWNLOAD_OUR_MOBILE_APP} */}
                    {/* {lan.DOWNLOAD_OUR_MOBILE_APP} */}
                    {/* </span> */}
                    {/* </div> */}
                    {/* <div
                      style={{
                        marginLeft: "12px",
                        marginRight: "15px",
                        marginBottom: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <a
                        href="https://apps.apple.com/lk/app/saruwata-lk/id1565804524"
                        target="_blank"
                      >
                        <img
                          src="assets/icon/btn-apple-store-app.svg"
                          style={{ width: 114, height: 38 }}
                        ></img>
                      </a>
                    </div>
                    <div
                      style={{
                        marginBottom: "8px",
                        marginRight: "34px",
                        cursor: "pointer",
                      }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=lk.hiruads.aphrodite"
                        target="_blank"
                      >
                        <img
                          src="assets/icon/btn-google-play-app.svg"
                          style={{ width: 114, height: 38 }}
                        ></img>
                      </a>
                    </div> */}
                  </div>
                )}
                <div>
                  <ButtonGroup variant="text" style={{ backgroundColor: "primary", marginRight: "50px", border: "1px solid white" }} >
                    <Button variant='outlined' style={{ color: "#fff", fontSize: '14px', }} onClick={() => { dispatch(setLanguage('si')); }}>සිංහල</Button>
                    <Button variant='outlined' style={{ color: "#fff", fontSize: '12px', borderLeft: "1px solid white", borderRight: "1px solid white" }} onClick={() => { dispatch(setLanguage('en')); }}>English</Button>
                    <Button variant='outlined' style={{ color: "#fff", fontSize: '12px' }} onClick={() => { dispatch(setLanguage('ta')); }}>தமிழ்</Button>
                  </ButtonGroup>
                </div>

                <div>
                  {user != null && user.email !== "" ? (
                    <>
                      <Button
                        id="btn-login"
                        variant="text"
                        onClick={handleProfileMenuClick}
                      >
                        <Avatar
                          alt="Profile Image"
                          src={
                            user?.image == ""
                              ? "assets/icon/img-blank-profile-avatar.jpg"
                              : user.image
                          }
                          className={classes.small}
                        />
                        <span style={{ color: "#fff" }}>{user.name}</span>
                        <img
                          src="assets/icon/menu-dropdown-arrow.svg"
                          style={{
                            width: 8,
                            height: 6,
                            marginLeft: "8px",
                            marginTop: "2px",
                          }}
                        ></img>
                      </Button>
                      <Menu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        onClose={() => setAnchorEl(null)}
                        className={classes.menu}
                      >
                        <NavigationContext.Consumer>
                          {(nav: any) => (
                            <>
                              <MenuItem
                                onClick={() =>
                                  handleProfileMenuItemClick("profile")
                                }
                                className={classes.menuitem}
                                style={
                                  nav.current === NAV_ACCOUNT
                                    ? {
                                      fontWeight: 600,
                                      borderBottom: "1px solid #DDDDDD",
                                    }
                                    : { borderBottom: "1px solid #DDDDDD" }
                                }
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <img src="assets/icon/menu-icon-profile-normal-blue.svg"></img>
                                </ListItemIcon>
                                My Profile
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  handleProfileMenuItemClick("myads")
                                }
                                className={classes.menuitem}
                                style={
                                  nav.current === NAV_MYADS
                                    ? {
                                      fontWeight: 600,
                                      borderBottom: "1px solid #DDDDDD",
                                    }
                                    : {
                                      borderBottom: "1px solid #DDDDDD"
                                    }
                                }
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <img src="assets/icon/icon-my-ads.svg"></img>
                                </ListItemIcon>
                                My Ads
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  handleProfileMenuItemClick("likes")
                                }
                                className={classes.menuitem}
                                style={
                                  nav.current === NAV_LIKES
                                    ? {
                                      fontWeight: 600,
                                      borderBottom: "1px solid #DDDDDD"
                                    }
                                    : {
                                      borderBottom: "1px solid #DDDDDD"
                                    }
                                }
                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <img src="assets/icon/menu-icon-like-normal-blue.svg"></img>
                                </ListItemIcon>
                                My Favorites
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  dispatch(signOut(onSignOut))
                                }
                                className={classes.menuitem}

                              >
                                <ListItemIcon className={classes.menuIcon}>
                                  <img src="assets/icon/icon-log-out.svg"></img>
                                </ListItemIcon>
                                Log Out
                              </MenuItem>
                            </>
                          )}
                        </NavigationContext.Consumer>
                      </Menu>
                    </>
                  ) : (
                    <Button
                      id="btn-login"
                      onClick={handleLogin}
                      disabled={authFreeze}
                    >
                      <img
                        src="assets/icon/menu-icon-profile-normal.svg"
                        style={{
                          width: "22px",
                          height: "23px",
                          marginRight: "10px",
                        }}
                      ></img>
                      <span style={{ color: "#fff" }}>
                        {!authFreeze ? "Login" : " Processing..."}
                      </span>
                    </Button>
                  )}

                  <AddPostButton
                    handleNewAd={handleNewAd}
                    authFreeze={authFreeze}
                    language={getLanguage()}
                  ></AddPostButton>
                </div>
              </div>
            </Toolbar>
          )}
        </UserContext.Consumer>
      </Container>
    </AppBar>
  );
}
