import {
  Avatar,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSellerProfile } from "../../features/seller/sellerSlice";
import AlternateEmailSharpIcon from "@material-ui/icons/AlternateEmail";
import PhoneIcon from "@material-ui/icons/Phone";
import { logEvent } from "firebase/analytics"
import { MAKE_CALL } from "../../config/events";
import { ACTION_CLICK } from "../../config/actions";
import Slide from '@material-ui/core/Slide';
import { MetadataGenerator } from 'metatags-generator';
import { useLocation } from "react-router-dom";
import { frontend } from "../../config/urls";
import { analytics } from "../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    largeavatar: {
      width: 50,
      height: 52,
      border: 1,
    },
    headerwrapper: {
      padding: "20px",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #ddd",
    },
    name: {
      fontWeight: 700,
      marginLeft: "20px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: 2,
      boxOrient: "vertical",
    }
    ,
    shopname: {
      fontWeight: 700,
      marginLeft: "20px",
      overflow: "hidden",
      lineClamp: 2,
      boxOrient: "vertical",
      fontSize: "17px",
      marginTop: "20px"
    },
    addressText: {
      fontSize: 14,
      color: "#5A5A5A",
      marginLeft: "20px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: 2,
      boxOrient: "vertical",
      display: "-webkit-box",
      maxWidth: 170,
    },
    emailwrapper: {
      display: "flex",
      padding: "20px",
      alignItems: "center",
    },
    email: {
      color: "#000",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
    phonewrapper: {
      margin: "22px 0",
      display: "flex",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      padding: "10px",
      color: "#000",
      alignItems: "center",
      cursor: "pointer",
      border: "1px solid #DDDDDD",
      boxShadow: "0px 2px 0px #DDDDDD",
      height: 60,
    },
    sendMail: {
      margin: "22px 0",
      display: "flex",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      padding: "10px",
      color: "#000",
      alignItems: "center",
      cursor: "pointer",
      border: "1px solid #DDDDDD",
      boxShadow: "0px 2px 0px #DDDDDD",
      height: 50,
    },
    address: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      padding: "10px",
      border: "1px solid #DDDDDD",
      boxShadow: "0px 2px 0px #DDDDDD",
      height: 50,
      width: 50,
    },
    phoneicon: {
      lineHeight: 0,
      marginRight: "18px",
      marginLeft: "10px",
    },
    phonetext: {
      fontSize: "12px",
      color: "#5A5A5A",
    },
    phoneNumber: {
      fontSize: 18,
      fontWeight: 600,
    },
    openDetails: {
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      margin: "22px",
      color: "#000",
      border: "1px solid #DDDDDD",
      boxShadow: "0px 2px 0px #DDDDDD",
      display: "flex",
      padding: "15px",
      justifyContent: "space-between",

    },
    locationDetails: {
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      margin: "22px 0",
      color: "#000",
      border: "1px solid #DDDDDD",
      boxShadow: "0px 2px 0px #DDDDDD",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

    }
    ,
    aboutShop: {
      margin: "22px 0",
      color: "#000",
      borderTop: "2px solid #0080FF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "15px",
      backgroundColor: "#EBEEF1"

    }
  })
);

const SellerSidebarComponent = (props: any) => {
  const classes = useStyles();
  const profile = useSelector(selectSellerProfile);
  const [phone, showPhone] = useState(false);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    structuredData: true,
    androidChromeIcons: true,
    msTags: true,
    safariTags: true,
    appleTags: true,
    openGraphTags: true,
    twitterTags: false,
    facebookTags: false
  };
  useEffect(() => {
    const generator = new MetadataGenerator();
    const preparedData = generator
      .configure(settings)
      .setPageMeta({
        title: 'Saruwata.lk',
        description: profile?.shpDetails?.description,
        url: frontend + location.pathname,
        keywords: 'saruwata member, saruwata.lk,saruwata membership, membership,' + profile?.shpDetails?.shopName
      })
      .build();

    var headContent = document.getElementsByTagName('head')[0].innerHTML;
    document.getElementsByTagName('head')[0].innerHTML = preparedData.head + headContent;

  }, [])


console.log(profile);


  return (
    <section style={{ width: (isTabletOrMobile ? "100%" : "300px"), marginTop: "20px" }}>

      {profile?.member ?
        <>
          <div style={{ display: "flex", flexDirection: "column", borderTop: "2px solid #0080FF", backgroundColor: "#EBEEF1" }}>
            <div className={classes.shopname}>{(profile?.shpDetails.shopName != null ? profile?.shpDetails.shopName : profile?.name)}</div>
            <div style={{ display: "flex", position: "relative", marginLeft: "20px", }}>
              <div>
                <img style={{ width: "auto", height: "12px", marginRight: "10px" }} src="../../assets/icon/lable-premium-member.svg" />

              </div>

              {/* <div style={{ fontSize: "12px", padding: "2px 10px", borderLeft: "1px solid #DDDDDD" }}>Since July 2021</div> */}
            </div>
            {profile?.shpDetails?.shopOpenTimes?.length>0?
              <div className={classes.openDetails}>
              <div style={{ display: "flex", flexDirection: "column", padding: "auto", borderRight: "1px solid #DDDDDD", width: "100%" }}>
                <Typography style={{ fontSize: "12px", textAlign: "center" }}>TODAY</Typography>
                <Typography style={{ fontSize: "13px", textAlign: "center" }}>Open</Typography>

              </div>

              <div style={{ display: "flex", flexDirection: "column", padding: "auto", borderRight: "1px solid #DDDDDD", width: "100%" }}>
                <Typography style={{ fontSize: "12px", textAlign: "center" }}>CLOSING AT</Typography>
                <Typography style={{ fontSize: "13px", textAlign: "center" }}>6.00 pm</Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", padding: "auto" }}>
                <Typography onClick={handleClickOpen} style={{ fontSize: "13px" }}>Show all</Typography>

              </div>
            </div>
            :
            <div className={classes.openDetails}>
              <span>Please Add Shop Open Details</span>
            </div>
            }
          
          </div>
          <div className={classes.locationDetails}>
            <div style={{ padding: "15px" }}>
              <img style={{ width: "14px", height: "14px" }} src="../../assets/icon/icon-agent-location.svg" />
              <span style={{ fontSize: "14px", padding: "10px 10px", fontWeight: "bold" }}>Shop Location </span>
            </div>
            <Divider variant="fullWidth" />
            {/* <span style={{ fontSize: "14px", padding: "15px" }}>No: 23/27, Vihara Watta Road, Pathiragoda, Maharagama.</span> */}
            <span style={{ fontSize: "14px", padding: "15px" }}>{profile?.shpDetails.address}</span>
            <Divider variant="fullWidth" />
            <span style={{ fontSize: "12px", padding: "15px", color: "#0080ff", cursor: "pointer" }}>Show on Google map</span>
          </div>
          {/* {profile?.address && (
            <div style={{ display: "flex", alignItems: "center", margin: 22 }}>
              <div className={classes.address}>
                <img
                  src="assets/icon/icon-agent-location.svg"
                  style={{ width: 20, height: 24 }}
                  alt=""
                ></img>
              </div>
              <span className={classes.addressText}>{profile.address}</span>
            </div>
          )} */}
          {profile?.shpDetails?.mobiles == null ? (
            <div></div>
          ) : (
            // <div
            //   className={classes.phonewrapper}
            //   onClick={() => {
            //     showPhone(true);
            //     events.logEvent(MAKE_CALL, {
            //       action: ACTION_CLICK,
            //       platform: "DESKTOP",
            //     });
            //   }}
            // >
            //   <>
            //     <span className={classes.phoneicon}>
            //       <img
            //         src="assets/icon/icon-call-detail.svg"
            //         style={{ width: "24px", height: "24px" }}
            //       />
            //     </span>
            //     <span>
            //       <div className={classes.phoneNumber}>
            //         {phone ? profile?.phone : "07X XXX XXXX"}
            //       </div>
            //       <div className={classes.phonetext}>
            //         Click to show phone number
            //       </div>
            //     </span>
            //   </>
            // </div>


            <>
              {
                (profile?.shpDetails?.mobiles !== null ? profile?.shpDetails?.mobiles.map((element: any) => {
                  return (
                    <div
                      className={classes.phonewrapper}
                      onClick={() => {
                        showPhone(true);
                        logEvent(analytics, MAKE_CALL, {
                          action: ACTION_CLICK,
                          platform: "DESKTOP",
                        });
                      }}
                    >
                      <>

                        <span className={classes.phoneicon}>
                          <img
                            src="assets/icon/icon-call-detail.svg"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </span>
                        <span>
                          <div className={classes.phoneNumber}>
                            {phone ? element : "07X XXX XXXX"}
                          </div>
                          <div className={classes.phonetext}>
                            Click to show phone number
                          </div>
                        </span>
                      </>
                    </div>
                  )

                })
                  : null
                )



              }
            </>
          )}

          <div className={classes.sendMail}>
            <a className={classes.email} href={"mailto:" + profile?.email}>
              <span className={classes.phoneicon}>
                <img
                  src="assets/icon/icon-send-email.svg"
                  style={{ width: "24px", height: "18px" }}
                />
              </span>
              <div style={{ fontSize: "16px", fontWeight: 600 }}>Send email</div>
            </a>
          </div>

          <div className={classes.aboutShop}>
            <span style={{ fontWeight: 700, }}>About the shop</span>
            {/* <p style={{ fontSize: "12px" }}>We ensure that Auto Parts are sold in excellent performance conditions. Our goal is to cater to Sri Lankan client base while trying our best to meet their vehicle needs for highly competitive and reasonably priced quality Auto Parts. Auto Parts that are imported and redefined to know what perfection is, we will exceptionally let you know how the products serve and therefore perform.</p> */}
            <p style={{ fontSize: "12px" }}>{(profile?.shpDetails.description != null ? profile?.shpDetails.description : null)}</p>
          </div>
        </>
        :
        <>
          <div className={classes.headerwrapper}>
            {profile?.profile ? (
              <Avatar
                variant="square"
                alt="Profile Image"
                src={profile?.profile}
                className={classes.largeavatar}
              ></Avatar>
            ) : (
              <img
                src="assets/icon/icon-agent-profile-shop.svg"
                style={{ width: 50, height: 52 }}
              ></img>
            )}
            <div className={classes.name}>{profile?.name}</div>
          </div>
          {profile?.address && (
            <div style={{ display: "flex", alignItems: "center", margin: 22 }}>
              <div className={classes.address}>
                <img
                  src="assets/icon/icon-agent-location.svg"
                  style={{ width: 20, height: 24 }}
                  alt=""
                ></img>
              </div>
              <span className={classes.addressText}>{profile?.address}</span>
            </div>
          )}
          {profile?.phone === "" ? (
            <div></div>
          ) : (
            <>
              {


                <div
                  className={classes.phonewrapper}
                  onClick={() => {
                    showPhone(true);
                    logEvent(analytics, MAKE_CALL, {
                      action: ACTION_CLICK,
                      platform: "DESKTOP",
                    });
                  }}
                >
                  <>

                    <span className={classes.phoneicon}>
                      <img
                        src="assets/icon/icon-call-detail.svg"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </span>
                    <span>
                      <div className={classes.phoneNumber}>
                        {phone ? profile?.phone : "07X XXX XXXX"}
                      </div>
                      <div className={classes.phonetext}>
                        Click to show phone number
                      </div>
                    </span>
                  </>
                </div>

                // profile?.shpDetails?.mobiles.map((element: any) => {
                //   return (
                //     <div
                //       className={classes.phonewrapper}
                //       onClick={() => {
                //         showPhone(true);
                //         events.logEvent(MAKE_CALL, {
                //           action: ACTION_CLICK,
                //           platform: "DESKTOP",
                //         });
                //       }}
                //     >
                //       <>

                //         <span className={classes.phoneicon}>
                //           <img
                //             src="assets/icon/icon-call-detail.svg"
                //             style={{ width: "24px", height: "24px" }}
                //           />
                //         </span>
                //         <span>
                //           <div className={classes.phoneNumber}>
                //             {phone ? element : "07X XXX XXXX"}
                //           </div>
                //           <div className={classes.phonetext}>
                //             Click to show phone number
                //           </div>
                //         </span>
                //       </>
                //     </div>
                //   )

                // })

              }
            </>



          )}

          <div className={classes.sendMail}>
            <a className={classes.email} href={"mailto:" + profile?.email}>
              <span className={classes.phoneicon}>
                <img
                  src="assets/icon/icon-send-email.svg"
                  style={{ width: "24px", height: "18px" }}
                />
              </span>
              <div style={{ fontSize: "16px", fontWeight: 600 }}>Send email</div>
            </a>
          </div>
        </>
      }

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"xs"}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Open hours</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {profile?.shpDetails?.shopOpenTimes?.map((item: any) =>
              <Grid container >
                <Grid item xs={5} md={5} lg={5}>{item.day}</Grid>
                <Grid item xs={7} md={7} lg={7} style={{ textAlign: "right" }}>{item.openTime} - {item.closeTime}</Grid>
              </Grid>
            )}

            {/* <Grid container >
              <Grid item xs={5} md={5} lg={5}>Tuesday</Grid>
              <Grid item xs={7} md={7} lg={7} style={{ textAlign: "right" }}>9:00 am - 6:00 pm</Grid>
            </Grid>
            */}
          </DialogContentText>
        </DialogContent>

      </Dialog>



    </section>
  );
};

export default SellerSidebarComponent;
