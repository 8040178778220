import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControlLabel,
  Icon,
  makeStyles,
  Paper,
  Switch,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  
  selectMyPostsSummary,
  selectSelfProfile,
} from "../../features/session/sessionSlice";
import { UserContext } from "../../providers/UserProvider";
import PhoneIcon from "@material-ui/icons/Phone";
import "./MyAdsSidebarComponent.css";
import * as moment from "moment";
import { signOut } from "../../features/session/firebaseSlice";
import { setCurrentBehavior } from "../../features/navigation/navigationSlice";
import { LOGOUT } from "../../config/events";
import { BEHAVE_LOGIN_REQUIRED } from "../../providers/NavigationProvider";
import { ACTION_CLICK } from "../../config/actions";
import { logEvent } from "firebase/analytics"
import { CustomPhoneValidator } from "../ValidatedPhones/CustomPhoneValidator";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { clearAllPosts, clearLikedPosts } from "../../features/post/postSlice";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LinearProgress from '@material-ui/core/LinearProgress';
import axios from "axios";
import { GET_CURRENT_MEMBERSHIP_DETAILS } from "../../config/urls";
import { useNavigate } from "react-router-dom";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }),
)(LinearProgress);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    largeavatar: {
      width: "98px",
      height: "98px",
      border: 1,
    },
    changephonebtn: {
      color: "#0080ff",
      borderColor: "#0080ff",
      "& .MuiButton-label": {
        justifyContent: "flex-start",
      },
    },
    disablebtn: {
      color: "#ccc",
      borderColor: "#ccc",
    },
    topbar: {
      marginRight: "21px",
      "& svg": {
        color: "#ff7675",
      },
      "& #logoutbtn": {
        cursor: "pointer",
      },
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    membershicon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      position: "absolute",
      right: 0,
      bottom: 0,

    },
    membershipIconDiv: {
      position: "relative",
      width: theme.spacing(8),

    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '1',
      flexShrink: 0,
    },





  })
);

const MyAdsSidebarComponent = (props: any) => {
  const classes = useStyles();
  const selfPosts = useSelector(selectSelfProfile);
  const [totalViews, setTotalViews] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [phoneVerify, showPhoneVerify] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout, setLogout] = useState(false);

  const [phone, setPhone] = useState("");
  const [array, setArray] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [checkedMemCat, setCheckedMemCat] = React.useState(false);

  const myPostSummary = useSelector(selectMyPostsSummary);

  useEffect(() => {
    if (!selfPosts) return;
    calTotalLikes();
    calTotalViews();
    getMembershipsDetailsSummery();
  }, [selfPosts]);


  const calTotalViews = () => {
    setTotalViews(
      selfPosts.posts
        .map((p: any) => p.click_count)
        .reduce((a: any, b: any) => a + b, 0)
    );
  };

  const calTotalLikes = () => {
    setTotalLikes(
      selfPosts.posts
        .map((p: any) => p.like_count)
        .reduce((a: any, b: any) => a + b, 0)
    );
  };


  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const handleChangeMemCat = () => {
    setCheckedMemCat((prev) => !prev);
  };

  const getMembershipsDetailsSummery = () => {
    axios.get(GET_CURRENT_MEMBERSHIP_DETAILS).then(data => {

      if (data.data != null) {

        setArray(data.data);

      }


    }).catch(error => console.log(error))
  }


  const [currentUser, setCurrentUser] = useState(Object);
  return (
    <UserContext.Consumer>
      {(user: any) =>
        user == null || user?.email === "" ? null : (
          <div style={{ background: ' #ebeef1 0% 0% no-repeat padding-box', position: 'relative', paddingBottom: '20px' }}>
            <div
              style={{

                marginTop: "45px",
                background: "#ebeef1",
                borderTop: "2px solid #0080FF",
                display: "flex",

              }}
            >

              <div className={classes.topbar}>
                <div id="memberDetails">
                  <div className={classes.membershipIconDiv}>
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" className={classes.large} />

                    <Avatar alt="membership icon" src='../../../assets/icon/icon-membership.svg' className={classes.membershicon} />
                  </div>
                  <div id='membershipDetails' >
                    <span>{user.name}</span>
                    {/* <div>
                      <span>since July 2020</span>
                      <div>
                        <span>Edit</span>
                        <img src='../../assets/icon/icon-edit.svg' />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <section id="profile-sidebar-wrapperr">

              <div >
                <div>
                  <span>
                    <div className="account-sidebar-analyt-no">
                      {myPostSummary.totalLikes}
                    </div>
                    <div style={{ fontSize: "12px", color: "#5A5A5A" }}>
                      Total Favorites
                    </div>
                  </span>
                  <span></span>
                  <span>
                    <div className="account-sidebar-analyt-no">
                      {myPostSummary.totalViews}
                    </div>
                    <div style={{ fontSize: "12px", color: "#5A5A5A" }}>
                      Total Views
                    </div>
                  </span>
                </div>
              </div>
            </section>
            <section id="ads-sidebar-wrapper">
              <div>
                <div>
                  <div style={{ fontWeight: 600, fontSize: "14px" }}>All Ads</div>
                  <div style={{ fontSize: "14px", fontWeight: 600 }}>
                    {parseInt((myPostSummary.approved == null) ? 0 : myPostSummary.approved) +
                      parseInt((myPostSummary.pendingPayment == null) ? 0 : myPostSummary.pendingPayment) +
                      parseInt((myPostSummary.underReview == null) ? 0 : myPostSummary.underReview) +
                      parseInt((myPostSummary.rejected == null) ? 0 : myPostSummary.rejected) +
                      parseInt((myPostSummary.expired == null) ? 0 : myPostSummary.expired) +
                      parseInt((myPostSummary.sold == null) ? 0 : myPostSummary.sold)}
                  </div>

                </div>
                <div>
                  <div style={{ fontSize: "14px", color: "#5A5A5A" }}>Approved Ads</div>
                  <div style={{ fontSize: "14px", color: "#5A5A5A" }}>{(myPostSummary.approved == null) ? 0 : myPostSummary.approved}</div>
                </div>
                <div>
                  <div style={{ fontSize: "14px", color: "#5A5A5A" }}>Payment Pending</div>
                  <div style={{ fontSize: "14px", color: "#5A5A5A" }}>{(myPostSummary.pendingPayment == null) ? 0 : myPostSummary.pendingPayment}</div>
                </div>
                <div>
                  <div style={{ fontSize: "14px", color: "#5A5A5A" }}>Reviewing Ads</div>
                  <div style={{ fontSize: "14px", color: "#5A5A5A" }}>{(myPostSummary.underReview == null) ? 0 : myPostSummary.underReview}</div>
                </div>

                {(checked ?

                  <>
                    <div className="adsContainer">
                      <div style={{ fontSize: "14px", color: "#5A5A5A" }}>Rejected</div>
                      <div style={{ fontSize: "14px", color: "#5A5A5A" }}>{(myPostSummary.rejected == null) ? 0 : myPostSummary.rejected}</div>
                    </div>
                    <div className="adsContainer">
                      <div style={{ fontSize: "14px", color: "#5A5A5A" }}>Sold</div>
                      <div style={{ fontSize: "14px", color: "#5A5A5A" }}>{(myPostSummary.sold == null) ? 0 : myPostSummary.sold}</div>
                    </div>
                    <div className="adsContainer">
                      <div style={{ fontSize: "14px", color: "#5A5A5A" }}>Expired</div>
                      <div style={{ fontSize: "14px", color: "#5A5A5A" }}>{(myPostSummary.expired == null) ? 0 : myPostSummary.expired}</div>
                    </div>
                  </>

                  : <></>
                )}

                {(checked ?
                  <Typography onClick={handleChange} style={{ margin: "12px 20px", color: "#0080FF", textAlign: 'end', cursor: 'pointer', fontSize: "14px" }}>Less show</Typography>
                  : <Typography onClick={handleChange} style={{ margin: "12px 20px", color: "#0080FF", textAlign: 'end', cursor: 'pointer', fontSize: "14px" }}>Show more</Typography>
                )}
              </div>

            </section>


            <section id='membership-section'>
              <div style={{ cursor: "pointer" }} onClick={() => { navigate('/membership') }}>
                <Avatar src='../../../assets/icon/icon-membership.svg' />
                <span>Get a Saruwata Membership!</span>
              </div>
            </section>
            {

              array.map((item: any) => {
                return (

                  <section id='membershipCategory'>
                    <div>
                      <div>
                        <span>{item.membershipDisplayName}</span>
                        <span>{item.duration} days {item.membershipType} Membership</span>
                      </div>

                      <div>
                        <div>
                          <span>Ad limit</span>
                          <span>{item.useCount}/{item.adCount} Ads</span>
                        </div>
                        <div>
                          <BorderLinearProgress variant="determinate" value={(item.useCount / item.adCount) * 100} />
                          <div>
                            {/* <span>Membership period: 1 months</span> */}
                            <span></span>
                            <span >{(item.statusText === "ACTIVE" ? "Active" : "Pending")}</span>
                          </div>
                        </div>
                        <div>
                          {(checkedMemCat ?

                            < >

                              <div >
                                {/* <span>View invoice</span>
                                <span> No. 52652914</span> */}
                              </div>
                              <div>
                                <span>Last updated</span>
                                <span>{(item.startDate != null ? item.startDate : "")}</span>
                              </div>
                              <div >
                                <span>Expires on</span>
                                {/* <span>2021-10-01</span> */}
                                <span>{(item.endDate != null ? item.endDate : "")}</span>
                              </div>
                            </>

                            : < >

                            </>
                          )}

                          {(checkedMemCat ?
                            <Typography onClick={handleChangeMemCat} style={{ margin: "10px 0px", color: "blue", textAlign: 'end' }}>Less Show</Typography>
                            : <Typography onClick={handleChangeMemCat} style={{ margin: "0px 0px", color: "blue", textAlign: 'end' }}>Show More</Typography>
                          )}
                        </div>
                      </div>

                      <div>
                        <section id='voucher-sidebar-wrapper'>
                          <div >
                            <div>
                              <div>
                                <Avatar style={{ width: '30px', height: "30px" }} variant="square" src="../../../assets/icon/icon-voucher.svg" />

                              </div>
                              <div>
                                <Typography style={{ fontWeight: "bold", paddingTop: "2px" }}>Voucher</Typography>

                              </div>
                              <div>
                                <Button variant="outlined" size="small">History</Button>

                              </div>
                            </div>
                            <div>
                              <div>
                                <span>Free voucher value</span>
                                <span>(for {item.duration} days)</span>

                              </div>
                              <div>
                                {/* <span>Rs. 75,000</span> */}
                                <span>Rs. {item.voucherTotoalAmount - item.vpoucherUseAmount}</span>
                                {/* <span>Active</span> */}
                                <span>{((item.voucherTotoalAmount - item.vpoucherUseAmount) > 0) ? "Active" : "Deactive"}</span>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </section>


                )
              })
            }
          </div>
        )
      }
    </UserContext.Consumer>
  );
};

export default MyAdsSidebarComponent;
