import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Hidden, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles } from '@material-ui/core';
import { TabContext, TabPanel } from "@material-ui/lab";
import { NAV_ADD_POST } from '../../providers/NavigationProvider';
import { setCurrentLocation } from '../../features/navigation/navigationSlice';
import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
       
        welcome: {

        },
        title: {
            color: "#000000",
            fontSize: "20px",
            fontWeight: 600
        },

        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            overflow: 'visible',
        },
        paragraphs: {
            color: "#5A5A5A",
            fontSize: "14px"
        },

        contactFields: {
            width: "100%"
        },
        imageContact: {
            width: "52px",
            height: "52px"
        },
        buttonSend: {
            color: "#FFFFFF",
            backgroundColor: "#0080FF",
            width: "100%"
        },
        titleSell: {
            color: "#000000",
            fontSize: "18px",
            width: "100%"
        },
        inline: {
            display: 'inline',
        },

        heading: {
            fontSize: theme.typography.pxToRem(15),
            // fontWeight: theme.typography.fontWeightRegular,

        },

        divBackground: {
            marginTop: "10px",
            backgroundColor: "#F5F6F8",
            padding: "10px 10px",
            borderRadius: "4px"
        },
        indicator: {
            backgroundColor: '#3DAED9',
        }

    }),
);
export function Faq() {
    const classes = useStyles();
    const dispatch=useDispatch()
    const [value, setValue] = React.useState("");

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    function a11yProps(index: any) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    useEffect(()=>{
        dispatch(setCurrentLocation(NAV_ADD_POST));
    },[])
    return (

        <div>
            <Grid container>
                {/* <Hidden xsDown={true} smDown={true}>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                            classes={{
                                indicator: classes.indicator
                            }}
                        >
                            <Tab label="FAQ" {...a11yProps(0)} />
                            <Tab label="How to sell" {...a11yProps(1)} />
                            <Tab label="Contact Us" {...a11yProps(2)} />
                            <Tab label="About Us" {...a11yProps(3)} />
                        </Tabs>
                    </Grid>
                </Hidden> */}
                {/* <Hidden lgUp={true} mdUp={true}>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Tabs
                            orientation="horizontal"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                            classes={{
                                indicator: classes.indicator
                            }}
                        >
                            <Tab label="FAQ" {...a11yProps(0)} />
                            <Tab label="How to sell" {...a11yProps(1)} />
                            <Tab label="Contact Us" {...a11yProps(2)} />
                            <Tab label="About Us" {...a11yProps(3)} />
                        </Tabs>
                    </Grid>
                </Hidden> */}

                <Grid item lg={10} md={10} sm={12} xs={12}>
                    <TabContext value={value}>
                        <TabPanel value={value}>
                            <div>
                                <Typography className={classes.title}>Frequently Asked Questions</Typography>
                                <div className={classes.divBackground}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>1. Why do I need an account?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                It's a good thing to have an account! By placing your ads while logged in, you gain the advantage of being able to manage all of your ads in one convenient location; once logged in, 'My Ads' will list all of your ads. This is where you can edit, remove (Sold Out), and promote ads. Once logged in, you can like ads and save them to your favorites for later review.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>2. How do I post an ad?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                It's simple to place an ad on saruwata.lk! Simply click the yellow Post Ad button and then follow the on-screen instructions.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>3. How do I edit my ad?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                To edit an ad, go to the ad's page and select the "Edit ad" option.
                                                Tip: You can easily find your ad by logging in to your account and going to your "My Ads" page!
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>4. How long do the ads stay on Saruwata.lk?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Ads will remain visible for 60 days unless you remove them by selecting the "Sold out" option.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>5. How do I remove (sold-out) my ad?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Instead of deleting your ad, we have a "Sold out" option to remove it from the site. Please go to the "My Ads" page and select "Sold out."
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>6. How do I set a new password on Saruwata.lk?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                To change your Saruwata.lk password, log in to your account, navigate to the "Settings" page, and enter a new password.
                                                If you forgot your Saruwata.lk password, go to the log-in page and click the "Forgot your password?" link.
                                                You will not have a Saruwata.lk password if you have created an account by using Google authentication. You can easily access your account without a password by using Google.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>7. I posted an ad but can't find it.</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Tip: You can easily keep track of your ads by logging in to your account and going to your own Saruwata.lk account by using the "My Ads" option!
                                                Your ad may be unavailable due to one of the following factors:
                                                • It is still being reviewed - This will be visible on your "My Ads" page under "Ads under review."
                                                • It may have Violated the ad guidelines of our website - If your ad needs to be edited before it can be published, it will appear on your "My Ads" page under "Ads that need editing."
                                                If you have been waiting for more than 24 hours for a response from us, it is possible that you provided us with incorrect contact information when you posted the ad. Please try again or contact us.

                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>8. What’s the reason your ad got rejected?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                All advertisements are reviewed manually - if your advertisement violates our posting rules, it will be rejected. In that case, we shall notify you with a rejection email where you can see what changes need to be made before the ad can be approved. Otherwise, we will advise you on how to post the ad correctly in accordance with our rules.
                                                In such instances, you will be notified via text message or email. This will appear as a rejected ad on your account page, under "My Ads."
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>9. I'm getting contacted about an ad I didn't post?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Without a doubt, Please contact us and we will assist you as soon as possible.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>10. I haven't received any responses to my ad. What's wrong?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                If you are not receiving responses to our ads, we recommend looking at our tips on how to Promote your Ad
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>11. How does Saruwata.lk make money?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Saruwata.lk provides paid features and services that assist people in promoting their ads, facilitating the sale of advertised items, and providing businesses with a larger online presence.
                                                Currently, these include <u>ad promotions</u> and the Saruwata.lk <u>membership</u> service.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>12. How do I sign up for a user account on Saruwata.lk?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Signing up for an account on Saruwata.lk is simple, quick, and free! Please go to the Sign-up page and follow the instructions to sign up for Saruwata.lk. You can sign up by using your email address, as well as your Google account.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>13. How do I log in and log out of my account?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                To log in to your account, simply go to the Login page and enter your email and Saruwata.lk password. If you have created an account via Google, click on "Continue with Google'' and follow the instructions.
                                                To log out of your account, simply click the "Log out" option under “My Profile”.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>14. How do I change my account details?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Log in to your account and go to the ‘My Profile’ - 'Settings' page to change the details on your account.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>15. Why can't I log in to my account?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                If you are having trouble logging in to your account, please ensure that you have the following:
                                                ●	Valid Account
                                                ●	On the login page, enter the correct email address and password.
                                                Please contact us if you are still unable to access your account.

                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                </div>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Typography className={classes.title}>Paid products and services Q&A</Typography>
                                <div className={classes.divBackground}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>1. What is a "Super Ad"?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Super Ads are highlighted in yellow, larger than regular ads and clearly labeled as "Super Ad." Each Super Ad has an equal chance of appearing in the Top Ad slots. This translates to thousands of additional appearances for each Top Ad per day for the duration of the promotion.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>2. What is “Jump Ad”?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Jump ads appear once a day at the top of the regular ad listings and move down the page as new ads are posted.  Jump Ad feature will push your ad to the top of the page and receive up to ten times the number of views as regular ads. This process is repeated on a daily basis for the duration of the promotion.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>3. What is a “Featured Ad”?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Using a Featured Ad will reserve you a Premium space to showcase your high value products to get quick attention from buyers. Each Featured Ad has an equal chance of being shown in this super visible spot, with 5 ads shown to every user automatically. In addition to Featured Ad, your ad is also published as a regular ad.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>4. What is a “Quick Sale” promotion?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Stand out from the rest of the ads to show the urgency by marking your ad on a bright red line in the regular ad listing page. Combine this promotion with a Super Ad or Jump Ad to ensure your ad remains at the top of regular listings and receives up to ten times the number of views.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>5. How do I get a shop page for my business?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                All of our Membership packages include a Shop page, so signing up for one will automatically entitle you to own your own Shop page. Learn more about Memberships.
                                            </Typography>
                                            {/* <Typography>
                                            All of our Membership packages include a Shop page, so signing up for one will automatically entitle you to own your own Shop page. Learn more about <a href="/membership"> Memberships</a>.
                                        </Typography> */}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>6. What is a Membership?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Please visit our Membership page to learn more about it.
                                            </Typography>
                                            {/* <Typography>
                                            Please visit our <a href="/membership"> Membership</a> page to learn more about it.
                                        </Typography> */}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>7. How do I buy a Promotion?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                If you require any additional information or wish to purchase a Bulk Promotion, please contact us at support@saruwata.lk.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>8. How do Promotions work?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Your Promotions can be used to purchase any "Super Ad", "Jump Ad", ‘’Featured Ad’’ or ‘’Quick Sale’’ as long as the remaining balance covers the cost of the promotion.
                                                If your balance is insufficient to purchase a "Super Ad" or "Jump Ad," please contact us to transfer the remaining balance and top it up, or use another voucher with sufficient balance.
                                                Please keep in mind that Promotions vouchers are non-refundable and cannot be exchanged for cash.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Typography className={classes.title}>What are the rules for posting on Saruwata.lk?</Typography>
                                <Typography style={{ fontWeight: 600 }}>We don't allow ads that contain:</Typography>
                                <div style={{ marginTop: "10px" }}>
                                    <span className={classes.paragraphs}>●	an illegal item or service in Sri Lanka</span><br />
                                    <span className={classes.paragraphs}>●	a product or service that is not available in Sri Lanka</span><br />
                                    <span className={classes.paragraphs}>●	an erroneous phone number or email address</span><br />
                                    <span className={classes.paragraphs}>●	an unrealistic offer.</span><br />
                                    <span className={classes.paragraphs}>●	offensive language.</span><br />
                                    <span className={classes.paragraphs}>●	offensive pictures.</span><br />
                                    <span className={classes.paragraphs}>●	Unrelated text in the title or description.</span><br />
                                    <span className={classes.paragraphs}>●	pictures that do not match or clearly show the advertised item or service.</span><br />
                                    <span className={classes.paragraphs}>●	text in the first picture (except logos and product codes).</span><br />
                                    <span className={classes.paragraphs}>●	a non-specific item or service, e. g. a description of a company in general terms.</span><br />
                                    <span className={classes.paragraphs}>●	a URL link that is not relevant to the advertised item or service.</span><br />
                                    <span className={classes.paragraphs}>●	offers and requests for items or services in the same ad.</span><br />
                                    <span className={classes.paragraphs}>●	exact same content as another ad, re-published within 7 days.</span><br />
                                    <span className={classes.paragraphs}>●	multiple items in the same ad.</span><br />
                                    <span className={classes.paragraphs}>●	counterfeit goods, knockoffs, or replica versions of another company’s product.</span><br />
                                    <span className={classes.paragraphs}>●	In addition, once the ad is posted, the product or service in the ad cannot be changed.</span><br />

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} >
                            <Typography className={classes.title}>How to sell fast you ad?</Typography>
                            <div style={{ display: "flex", marginTop: "15px" }}>
                                <img src="assets/icon/icon-money.svg" />
                                <div style={{ marginLeft: "20px" }}>
                                    <Typography className={classes.titleSell}>Pick the right price - everything sells if the price is right.</Typography>
                                    <span className={classes.paragraphs}>• Browse similar ads and pick a competitive price. </span><br />
                                    <span className={classes.paragraphs}>• Think about how much buyers are willing to pay. The lower the price, the higher the demand.</span>

                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: "25px" }}>
                                <img src="assets/icon/icon-ad-picture.svg" />
                                <div style={{ marginLeft: "20px" }}>
                                    <Typography className={classes.titleSell}>Use great photos</Typography>
                                    <span className={classes.paragraphs}>• Use actual photos - ads with photos of the real item get up to 10 times more views than ads with catalogue images.</span><br />
                                    <span className={classes.paragraphs}>• Take clear photos - use good lighting and different angles.</span>

                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: "25px" }}>
                                <img src="assets/icon/icon-pencil-edit.svg" />
                                <div style={{ marginLeft: "20px" }}>
                                    <Typography className={classes.titleSell}>Provide clear details in your ad</Typography>
                                    <span className={classes.paragraphs}>• More details = more views! </span><br />
                                    <span className={classes.paragraphs}>• Include keywords and information that buyers will be interested in. </span><br />
                                    <span className={classes.paragraphs}>• Be honest in your description.</span>

                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: "25px" }}>
                                <img src="assets/icon/icon-promotions.svg" />
                                <div style={{ marginLeft: "20px" }}>
                                    <Typography className={classes.titleSell}>Promote your ad!</Typography>
                                    <span className={classes.paragraphs}>• Promoted ads get up to 10 times more views.</span><br />
                                    <span className={classes.paragraphs}>• More views = more interested buyers.</span><br />
                                    <span className={classes.paragraphs}>• With lots of interested buyers, you have a better chance of selling fast for the price that you want.</span>

                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel value={value} >
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className={classes.title}>Contact us</Typography>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div style={{ display: "flex" }}>
                            <img src="assets/icon/icon-support.svg" />
                            <p className={classes.paragraphs} style={{ marginLeft: "10px" }}>If you did not find the answer to your question or problem, please get in touch with us using the form below and we will respond to your message as soon as possible.</p>
                        </div>
                    </Grid> */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ display: "flex" }}>
                                        <img src="assets/icon/icon-support.svg" />
                                        <p className={classes.paragraphs} style={{ marginLeft: "10px" }}>If you did not find the answer to your question or problem, please get in touch with us using the form below and we will respond to your message as soon as possible.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField id="contact-name" label="Your Name" variant="outlined" className={classes.contactFields} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField id="contact-email" label="Your Email" variant="outlined" className={classes.contactFields} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        className={classes.contactFields}
                                        id="contact-message"
                                        label="Your Message"
                                        multiline
                                        rows={10}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <Button className={classes.buttonSend}>Send Message</Button>
                                </Grid>
                            </Grid>
                            {/* <Typography className={classes.title}>Contact us</Typography>
                <div style={{ display: "flex" }}>
                    <img src="assets/icon/icon-support.svg" />
                    <p className={classes.paragraphs} style={{ marginLeft: "10px" }}>If you did not find the answer to your question or problem, please get in touch with us using the form below and we will respond to your message as soon as possible.</p>
                </div>
                <div style={{ marginTop: "5px" }}>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextField id="contact-name" label="Your Name" variant="outlined" className={classes.contactFields} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="contact-email" label="Your Email" variant="outlined" className={classes.contactFields} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                className={classes.contactFields}
                                id="contact-message"
                                label="Your Message"
                                multiline
                                rows={10}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <Button className={classes.buttonSend}>Send Message</Button>
                        </Grid>
                    </Grid>
                </div> */}
                        </TabPanel>
                        <TabPanel value={value}>
                            <Typography className={classes.title}>Who is Saruwata.lk?</Typography>
                            <div className={classes.paragraphs}>
                                <p>Saruwata.lk is a website where you can buy and sell almost everything. The best deals are often done with people who live in your own city or on your own street, so on saruwata.lk it's easy to buy and sell locally. All you have to do is select your region. </p>
                                <p>It takes less than 2 minutes to post an ad on saruwata.lk. You can sign up for a free account and post ads easily every time.</p>
                                <p>saruwata.lk has the widest selection of popular second hand items all over Sri Lanka, which makes it easy to find exactly what you are looking for. So if you're looking for a car, mobile phone, house, computer or maybe a pet, you will find the best deal on saruwata.lk.</p>
                                <p>saruwata.lk does not specialize in any specific category - here you can buy and sell items in more than 50 different categories. We also carefully review all ads that are being published, to make sure the quality is up to our standards.</p>
                                <p>If you'd like to get in touch with us, go to <span style={{ color: "#0080FF" }}>Contact us.</span></p>
                            </div>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </div>
    )
}