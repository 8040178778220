import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  useIonViewDidEnter,
} from "@ionic/react";
import { createStyles, makeStyles, Paper, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SelectCategoryLanding.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(2),
      },
    },
    paper: {
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
  })
);

const PAGE_MOTORS = 1;
const PAGE_MOBILE_ELECTRONICS = 2;
const PAGE_PROPERTY_SALE = 3;
const PAGE_PROPERTY_RENT = 4;
const PAGE_CLASSIFIEDS = 5;
const PAGE_OTHER = -1;

const SelectCategoryLanding = () => {
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  // var lan = require('../../../language/' + getLanguage());
  var l = new Language(getLanguage());
  var lan = l.getLan as any;
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollContent = document.getElementById("scrollable-root");
  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));

  const checkNav = (cat: any) => {
    handleScroll();
    navigate(`/search?c1=${encodeURIComponent(cat)}`);
  };


  const handleScroll = () => {
    scrollContent?.scrollTo(0, 0);
  };

  const icons = [
    "assets/icon/icon-motors.svg",
    "assets/icon/icon-electronics.svg",
    "assets/icon/icon-property-for-sale.svg",
    "assets/icon/icon-proerty-for-rent.svg",
    "assets/icon/icon-classifies.svg",
  ];

  return (
    <div id="main-container-sc">
      <section
        className={classes.root}
        style={{
          justifyContent: `${smallViewPort ? "space-between" : "center"}`,
        }}
      >
        {/* test start */}
        {/* <Paper
          id="btn-border-sc-landing"
          className={classes.paper}
          elevation={0}
          onClick={() => checkNav1("PaymentOverview")}
        >
          <div id="btn-inner-sc">
            <div>
              <IonIcon
                id="icon-car-sc"
                src="assets/icon/icon-motors.svg"
              ></IonIcon>
            </div>
            <div className="cat-title-text">
              <span className="btn-names-sc">Test</span>
            </div>
          </div>
        </Paper> */}

        {/* test end */}
        <Paper
          id="btn-border-sc-landing"
          className={classes.paper}
          elevation={0}
          onClick={() => checkNav("motors")}
        >
          <div id="btn-inner-sc">
            <div>
              <IonIcon
                id="icon-car-sc"
                src="assets/icon/icon-motors.svg"
              ></IonIcon>
            </div>
            <div className="cat-title-text">
              <span style={{ fontSize: (getLanguage() !== 'english' ? '10px' : '13px') }} className="btn-names-sc">{lan.MOTORS}</span>
            </div>
          </div>
        </Paper>
        <Paper
          id="btn-border-sc-landing"
          className={classes.paper}
          elevation={0}
          onClick={() => checkNav("mobiles and electronics")}
        >
          <div id="btn-inner-sc">
            <div>
              <IonIcon
                id="icon-mobile-sc"
                src="assets/icon/icon-electronics.svg"
              ></IonIcon>
            </div>
            <div className="cat-title-text">
              <span style={{ fontSize: (getLanguage() !== 'english' ? '10px' : '13px') }} className="btn-names-sc">{lan.MOBILE_AND_ELECTRONICS}</span>
            </div>
          </div>
        </Paper>
        <Paper
          id="btn-border-sc-landing"
          className={classes.paper}
          elevation={0}
          onClick={() => checkNav("property for sale")}
        >
          <div id="btn-inner-sc">
            <div>
              <IonIcon
                id="icon-car-sc"
                src="assets/icon/icon-property-for-sale.svg"
              ></IonIcon>
            </div>
            <div className="cat-title-text">
              <span style={{ fontSize: (getLanguage() !== 'english' ? '10px' : '13px') }} className="btn-names-sc">{lan.PROPERTY_FOR_SALE}</span>
            </div>
          </div>
        </Paper>
        <Paper
          id="btn-border-sc-landing"
          className={classes.paper}
          elevation={0}
          onClick={() => checkNav("property for rent")}
        >
          <div id="btn-inner-sc">
            <div>
              <IonIcon
                id="icon-car-sc"
                src="assets/icon/icon-proerty-for-rent.svg"
              ></IonIcon>
            </div>
            <div className="cat-title-text">
              <span style={{ fontSize: (getLanguage() !== 'english' ? '10px' : '13px') }} className="btn-names-sc">{lan.PROPERTY_FOR_RENT}</span>
            </div>
          </div>
        </Paper>
        <Paper
          id="btn-border-sc-landing"
          className={classes.paper}
          elevation={0}
          onClick={() => checkNav("classifieds")}
        >
          <div id="btn-inner-sc">
            <div>
              <IonIcon
                id="icon-car-sc"
                src="assets/icon/icon-classifies.svg"
              ></IonIcon>
            </div>
            <div className="cat-title-text">
              <span style={{ fontSize: (getLanguage() !== 'english' ? '10px' : '13px') }} className="btn-names-sc">{lan.CLASSIFIEDS}</span>
            </div>
          </div>
        </Paper>
      </section>
    </div>
  );
};

export default SelectCategoryLanding;
