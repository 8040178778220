import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelfPosts,
  selectAuthFreeze,
  selectLoggedUser,
  selectMyPostsSummary,
  selectSelfProfile,
  selectUserType,
  setPostSummary,
} from "../../features/session/sessionSlice";
import { ACTION_VIEW } from "../../config/actions";
import {
  setCurrentBehavior,
  setCurrentLocation,
} from "../../features/navigation/navigationSlice";
import {
  BEHAVE_LOGIN_REQUIRED,
  NAV_ACCOUNT, NAV_ADD_POST, NAV_MYADS,
} from "../../providers/NavigationProvider";
import { UserContext } from "../../providers/UserProvider";
import {
  EditPost,
  selectMyAds,
} from "../../features/post/postSlice";
import { Post } from "../../features/post/Post";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { VIEW_MYADS_PAGE, VIEW_PROFILE_PAGE } from "../../config/events";
import MyAdPost from "../../components/Post/MyAdPost/MyAdPost";
import { Avatar, Button, createStyles, Hidden, makeStyles, Snackbar, Switch, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { isPostResellable } from "../../features/helper";
import AddPostButton from "../../components/AddPost/AddPostButton/AddPostButton";
import axios from "axios";
import { GET_MY_AD_SIDEBAR_SUMMARY, MY_MEMBERSHIP_GET_USER_REQUEST, MY_MEMBERSHIP_GET_USER_REQUEST_TOKEN } from "../../config/urls";
import ToggleButton from "@material-ui/lab/ToggleButton";
import MobileMyAdPost from "../../components/Post/MyAdPostMobile/MyAdPostMobile";
import MyAdPostNew from "../../components/Post/MyAdPost/MyAdPostNew";
import MyAdsSidebarComponent from "../../components/MyAdsSidebarComponent/MyAdsSidebarComponent";
import Alert from "@material-ui/lab/Alert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../..";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switch: {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff'
      },
      marginRight: '20px'
    },
    reviewing: {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff'
      },
      marginRight: '20px',
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: '#13C2C2'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#13C2C2'
      }
    },

    reselling: {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff'
      },
      marginRight: '20px',
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: '#02DEAF'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#02DEAF'
      }
    },
    soldout: {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff'
      },
      marginRight: '20px',
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: '#F5222D'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#F5222D'
      }
    },
    topbar: {
      marginRight: "21px",
      "& svg": {
        color: "#ff7675",
      },
      "& #logoutbtn": {
        cursor: "pointer",
      },
    }, membershipIconDiv: {
      position: "relative",
      width: theme.spacing(8),

    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    membershicon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      position: "absolute",
      right: 0,
      bottom: 0,

    },
    button: {
      margin: theme.spacing(1),
      alignContent: 'right',
      borderRadius: '40px'
    },

  })
);

var open = false;
export function setEditOpen() {
  open = true;

};

function MyAds() {
  const classes = useStyles();

  const user = useSelector(selectLoggedUser);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [requestEligible, setRequestEligible] = useState(false);
  const [page, setPage] = useState(1);
  const selfPosts = useSelector(selectSelfProfile);
  const myads = useSelector(selectMyAds);
  const topref: any = useRef();
  const scrollContent = document.getElementById('scrollable-root');
  const [accepted, setaccepted] = useState(false);
  const [reviewing, setreviewing] = useState(false);
  const [rejected, setrejected] = useState(false);
  const [pendingPayment, setpendingPayment] = useState(false);
  const [reselling, setreselling] = useState(false);
  const [soldout, setsoldout] = useState(false);
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [checked, setChecked] = React.useState(false);
  const myPostSummary = useSelector(selectMyPostsSummary);

  const [filteredItem, setfilteredItem] = useState([]);

  const authFreeze = useSelector(selectAuthFreeze);

  const userType = useSelector(selectUserType);
  const [currentUser, setCurrentUser] = useState(Object);
  const [isLoading, setIsLoading] = useState(true);
  const [membershipRequests, setMembershipRequests] = useState([]);
  const [requested, setRequested] = useState(false);


  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     setCurrentUser(user);
  //     setIsLoading(false)
  //   });
  // }, []);

  // useEffect(()=>{
  //   (isTabletOrMobile?navigate('/mads'):null)
  // });

  useEffect(() => {
    setRequestEligible(true);
    dispatch(setCurrentLocation(NAV_MYADS));
    scrollContent?.scrollTo(0, 0);
    logEvent(analytics, VIEW_MYADS_PAGE, { action: ACTION_VIEW });
    return () => {
      setRequestEligible(false);
      setPage(1);
    };
  }, []);

  useEffect(() => {
    setfilteredItem(myads);
    axios.get(GET_MY_AD_SIDEBAR_SUMMARY).then((result) => {

      dispatch(setPostSummary(result.data));

    }).catch(e => console.log(e));
  }, [myads]);

  useEffect(() => {

    if (!requested && (user.email!=''&&user.email!=undefined)) {
      axios.get(MY_MEMBERSHIP_GET_USER_REQUEST+"/" + user.email + MY_MEMBERSHIP_GET_USER_REQUEST_TOKEN).then((response) => {
        setMembershipRequests(response.data);
        setRequested(true);
      });
    }
  })


  useEffect(() => {
    setfilteredItem(myads
      .filter((item: any) => !accepted || (item.approval == 1 || item.approval == 0))
      .filter((item: any) => !rejected || item.approval == 4)
      .filter((item: any) => !reviewing || item.approval == 10)
      .filter((item: any) => !pendingPayment || item.approval == 5)
      .filter((item: any) => !reselling || isPostResellable(item, selfPosts?.unlimited_reselling))
      .filter((item: any) => !soldout || item.sold)
    );
  }, [accepted, rejected, reviewing, pendingPayment, reselling, soldout]);

  useEffect(() => {
    if (requestEligible && user?.email && selfPosts == null) fetchUserProfile();
  }, [user, requestEligible]);

  const fetchUserProfile = () => {
    dispatch(getSelfPosts());
  };

  const handleNewAd = (user: any) => {
    if (user == null || user.email === "") {
      handleLogin();
      return;
    }

    navigate("/add-post");
  };

  const handleLogin = () => {
    dispatch(setCurrentBehavior(BEHAVE_LOGIN_REQUIRED));
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const [selected1, setSelected1] = React.useState(false);
  const [selected2, setSelected2] = React.useState(false);
  const [selected3, setSelected3] = React.useState(false);
  const [selected4, setSelected4] = React.useState(false);
  const [selected5, setSelected5] = React.useState(false);
  const [selected6, setSelected6] = React.useState(false);

  const loadCardView = () => {

    return (
      <>
        {/* {(isTabletOrMobile ? window.location.href = ('/mads') : null)} */}
        <div id="account-list-header-wrapper">
          <div style={{ display: "flex" }}>
            <h5 style={{ fontSize: "18px", padding: "0 10px", fontWeight: 600 }}>My Ads</h5>
            {(membershipRequests.length > 0 ?
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                startIcon={<Avatar style={{ height: '22px', width: '22px' }} src="./assets/icon/icon-membership.svg" />}
                onClick={() => navigate("/my-membership")}
              >
                My Membership&nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '12px' }} />
              </Button>
              : null)}

            {(userType == "listing" ? null :

              <div >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  startIcon={<img src="./assets/icon/icon-seller-settings-button.svg" />}
                  onClick={() => navigate("/sellerPageSetting")}
                >
                  Shop Page Setting &nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '12px' }} />
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  // startIcon={<img src="./assets/icon/icon-seller-settings-button.svg" />}
                  onClick={() => window.location.href = ("/seller/" + user.email)}
                >
                  View My Shop &nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '12px' }} />
                </Button>


              </div>)}

          </div>
          <div className="pre-post-bar">
            <span>Manage Ads - (Posted Ads {selfPosts?.posts.length})</span>
          </div>
        </div>
        <div id="account-header-filter">
          <div style={{ width: '181px', borderRight: '1px solid #DDDDDD', height: '100%', display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '14px', color: '#5A5A5A', fontWeight: 600, marginLeft: '20px' }}>Show Only</span>
          </div>
          <div id="switch-wrapper">
            <span id="text-filter">Approved</span>
            <Switch
              checked={accepted}
              onChange={() => setaccepted(!accepted)}
              className={classes.switch}
              color='primary'
              size="small"
            />
          </div>
          <div id="switch-wrapper">
            <span id="text-filter">Reviewing</span>
            <Switch
              checked={reviewing}
              onChange={() => setreviewing(!reviewing)}
              className={classes.reviewing}
              size="small"
            />
          </div>
          <div id="switch-wrapper">
            <span id="text-filter">Rejected</span>
            <Switch
              checked={rejected}
              onChange={() => setrejected(!rejected)}
              className={classes.switch}
              size="small"
            />
          </div>
          <div id="switch-wrapper">
            <span id="text-filter">Pending Payment</span>
            <Switch
              checked={pendingPayment}
              onChange={() => setpendingPayment(!pendingPayment)}
              className={classes.reselling}
              size="small"
            />
          </div>
          <div>
            <span id="text-filter">Sold Out</span>
            <Switch
              checked={soldout}
              onChange={() => setsoldout(!soldout)}
              className={classes.soldout}
              size="small"
            />
          </div>
        </div>
        {getCardViews()}

        {/* {filteredItem.length > 0
            ? filteredItem.map((item: Post, index: number) => (
              <div key={item.id} style={{ paddingBottom: "5px" }}>

                <LazyLoadComponent
                  placeholder={
                    <img src="assets/images/Place-holder-listing.png" style={{ width: '915px', height: '108px' }}></img>
                  }
                  key={item.id}
                >

                  <MyAdPost
                    post={item}
                    position={index + 1}
                    detailsAction={() => { }}
                    unlimitedReselling={selfPosts?.unlimited_reselling}
                  ></MyAdPost>

                </LazyLoadComponent>

              </div>
            ))
            :
            <div className="no-text">
              <img
                src="assets/images/img-no-ad-posts.png"
                className="cactus"
                style={{ marginTop: '50px' }}
              ></img>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '29px', fontWeight: 400 }}>
                <div style={{ color: '#5A5A5A', fontSize: '18px' }}>You haven't placed any ads yet.</div>
                <div style={{ color: '#5A5A5A', fontSize: '14px' }}>Click the "Post Your Ad" button to post your ad.</div>
              </div>
              <AddPostButton handleNewAd={handleNewAd} authFreeze={authFreeze}></AddPostButton>
            </div>
          } */}

      </>
    );
  }

  const getCardViews = () => {

    console.log(filteredItem);
    

    return (
      filteredItem.length > 0
        ? filteredItem.map((item: Post, index: number) => (
          <div key={item.id} style={{ paddingBottom: "5px" }}>

            <LazyLoadComponent
              placeholder={
                <img src="assets/images/plase-holder-my-profile-listing.png" style={{ height: '108px' }}></img>
              }
              key={item.id}
            >

              <MyAdPostNew
                post={item}
                position={index + 1}
                detailsAction={() => { }}
                unlimitedReselling={selfPosts?.unlimited_reselling}
              ></MyAdPostNew>

            </LazyLoadComponent>

          </div>
        ))
        :
        <div className="no-text">
          <img
            src="assets/images/img-no-ad-posts.png"
            className="cactus"
            style={{ marginTop: '50px' }}
          ></img>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '29px', fontWeight: 400 }}>
            <div style={{ color: '#5A5A5A', fontSize: '18px' }}>You haven't placed any ads yet.</div>
            <div style={{ color: '#5A5A5A', fontSize: '14px' }}>Click the "Post Your Ad" button to post your ad.</div>
          </div>
          {/* <AddPostButton handleNewAd={handleNewAd} authFreeze={authFreeze}></AddPostButton> */}
        </div>
    )
  }



  const loadCardViewMobile = () => {

    return (
      <>


        {/* <MySideBarContent /> */}

        <div style={{ background: ' #ebeef1 0% 0% no-repeat padding-box', position: 'relative', paddingBottom: '20px' }}>
          <div
            style={{

              // marginTop: "45px",
              background: "#ebeef1",
              borderTop: "2px solid #0080FF",
              display: "flex",

            }}
          >
            <div className={classes.topbar}>
              <div id="memberDetails">
                <div className={classes.membershipIconDiv}>
                  <Avatar alt={user.name} src={user.image} className={classes.large} />

                  <Avatar alt="membership icon" src='../../../assets/icon/icon-membership.svg' className={classes.membershicon} />
                </div>
                <div id='membershipDetails' >
                  <span>{user.name}</span>
                  <div>
                    {/* <span>since July 2020</span> */}
                    <div>
                      {/* <span>Edit</span>
                      <img src='../../assets/icon/icon-edit.svg' /> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <section id="profile-sidebar-wrapperr">

            <div >
              <div>
                <span>
                  <div className="account-sidebar-analyt-no">
                    {myPostSummary.totalLikes}
                  </div>
                  <div style={{ fontSize: "12px", color: "#5A5A5A" }}>
                    Total Favorites
                  </div>
                </span>
                <span></span>
                <span>
                  <div className="account-sidebar-analyt-no">
                    {myPostSummary.totalViews}
                  </div>
                  <div style={{ fontSize: "12px", color: "#5A5A5A" }}>
                    Total Views
                  </div>
                </span>


              </div>
            </div>
          </section>
          <section id="ads-sidebar-wrapper">
            <div>
              <div>
                <div style={{ fontWeight: 600 }}>All Ads</div>
                <div>
                  {parseInt((myPostSummary.approved == null) ? 0 : myPostSummary.approved) +
                    parseInt((myPostSummary.pendingPayment == null) ? 0 : myPostSummary.pendingPayment) +
                    parseInt((myPostSummary.underReview == null) ? 0 : myPostSummary.underReview) +
                    parseInt((myPostSummary.rejected == null) ? 0 : myPostSummary.rejected) +
                    parseInt((myPostSummary.expired == null) ? 0 : myPostSummary.expired) +
                    parseInt((myPostSummary.sold == null) ? 0 : myPostSummary.sold)}
                </div>

              </div>
              <div>
                <ToggleButton
                  className="tbtn"
                  value="check"
                  style={{ border: "none", width: "100%", color: "black", padding: "1px 0", borderRadius: "0", textTransform: "none" }}
                  selected={selected1}
                  onChange={() => {
                    setSelected1(!selected1);
                    setaccepted(!selected1);
                  }}
                >
                  <div style={{ width: "97%", textAlign: "left" }}>Approved Ads</div>
                  <div>
                    {(myPostSummary.approved == null) ? 0 : myPostSummary.approved}
                  </div>
                </ToggleButton>



              </div>
              <div>

                <ToggleButton
                  className="tbtn"
                  value="check"
                  style={{ border: "none", width: "100%", color: "black", padding: "1px 0", borderRadius: "0", textTransform: "none" }}
                  selected={selected2}
                  onChange={() => {
                    setSelected2(!selected2);
                    setpendingPayment(!selected2);
                  }}
                >
                  <div style={{ width: "97%", textAlign: "left" }}>Payment Pending</div>
                  <div>
                    {(myPostSummary.pendingPayment == null) ? 0 : myPostSummary.pendingPayment}
                  </div>
                </ToggleButton>
              </div>
              <div>

                <ToggleButton
                  className="tbtn"
                  value="check"
                  style={{ border: "none", width: "100%", color: "black", padding: "1px 0", borderRadius: "0", textTransform: "none" }}
                  selected={selected3}
                  onChange={() => {
                    setSelected3(!selected3);
                    setreviewing(!selected3);
                  }}
                >
                  <div style={{ width: "97%", textAlign: "left" }}>Reviewing Ads</div>
                  <div>{(myPostSummary.underReview == null) ? 0 : myPostSummary.underReview}</div>

                </ToggleButton>

              </div>

              {(checked ?

                <>
                  <div className="adsContainer">
                    <ToggleButton
                      className="tbtn"
                      value="check"
                      style={{ border: "none", width: "100%", color: "black", padding: "1px 0", borderRadius: "0", textTransform: "none" }}
                      selected={selected4}
                      onChange={() => {
                        setSelected4(!selected4);
                        setrejected(!selected4);
                      }}
                    >
                      <div style={{ width: "97%", textAlign: "left" }}>Rejected</div>
                      <div>{(myPostSummary.rejected == null) ? 0 : myPostSummary.rejected}</div>

                    </ToggleButton>

                  </div>
                  <div className="adsContainer">
                    <ToggleButton
                      className="tbtn"
                      value="check"
                      style={{ border: "none", width: "100%", color: "black", padding: "1px 0", borderRadius: "0", textTransform: "none" }}
                      selected={selected5}
                      onChange={() => {
                        setSelected5(!selected5);
                        setsoldout(!selected5);
                      }}
                    >
                      <div style={{ width: "97%", textAlign: "left" }}>Sold</div>
                      <div>{(myPostSummary.sold == null) ? 0 : myPostSummary.sold}</div>

                    </ToggleButton>

                  </div>
                  <div className="adsContainer">
                    <ToggleButton
                      className="tbtn"
                      value="check"
                      style={{ border: "none", width: "100%", color: "black", padding: "1px 0", borderRadius: "0", textTransform: "none" }}
                      selected={selected6}
                      onChange={() => {
                        // setSelected6(!selected6);

                      }}
                    >
                      <div style={{ width: "97%", textAlign: "left" }}>Expired</div>
                      <div>{(myPostSummary.expired == null) ? 0 : myPostSummary.expired}</div>

                    </ToggleButton>
                    {/* <div>Expired</div>
                      <div>{(myPostSummary.expired == null) ? 0 : myPostSummary.expired}</div> */}
                  </div>
                </>

                : <></>
              )}

              {(checked ?
                <Typography onClick={handleChange} style={{ margin: "12px 20px", color: "blue", textAlign: 'end', cursor: 'pointer' }}>Less Show</Typography>
                : <Typography onClick={handleChange} style={{ margin: "12px 20px", color: "blue", textAlign: 'end', cursor: 'pointer' }}>Show More</Typography>
              )}
            </div>

          </section>


        </div>

        {getCardViews()}




      </>
    );
  }

  const goHome = () => {
    navigate('/home')
  }


  return (


    // <AuthContext.Provider
    //   value={{ currentUser, isLoading }}
    // >
    //   {(currentUser == null ? (
    //     <Redirect to='/home' />

    //   ) : (

    //     <div ref={topref}>
    //       {loadCardView()}
    //       <div style={{ height: 50 }}></div>

    //     </div>
    //   ))
    //   }
    // </AuthContext.Provider>

    // <UserContext.Consumer>
    <>

      {
        user == null || user.email === '' ? (goHome) : (

          <div style={{ backgroundColor: "#ebeef1" }} ref={topref}>

            <Hidden xsDown={true} smDown={true}>
              {loadCardView()}
            </Hidden>
            <Hidden lgUp={true} mdUp={true}>
              {loadCardViewMobile()}
            </Hidden>

            <div style={{ height: 50 }}></div>
          </div>
        )
      }
      <Snackbar open={open} autoHideDuration={2000} onClose={() => {
        open = false;
        window.location.href = ('/myads');
      }} >
        <Alert style={{ backgroundColor: "green", color: "white" }} severity="success">
          Update Sent.Wait Until Approval
        </Alert>
      </Snackbar>
    </>
  );
};

export default MyAds;
