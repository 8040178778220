import { IonAlert } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectCategory from "../../components/AddPost/Category/SelectCategory";
import AddContact from "../../components/AddPost/Contact/AddContact";
import FillDetails, { getMandatoryDetails } from "../../components/AddPost/FillDetails/FillDetails";
import SubCategory from "../../components/AddPost/SubCategory/SubCategory";
import {
  selectCurrentStep,
  selectedCategories,
  setCurrentStep,
  setSelectedCategories,
} from "../../features/addPost/addPostSlice";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { NAV_ADD_POST } from "../../providers/NavigationProvider";
import "./AddPost.css";
import {
  addNewPostRawImages,
  clearAllPosts,
  clearImages,
  clearNewPost,
  getAllAvailablePosts,
  pushPost,
  pushPostWithoutPayment,
  selectNewPosts,
  selectSelectedImages,
  setImgFolder,
} from "../../features/post/postSlice";
import {
  getAllCategories,
  selectCategories,
} from "../../features/tags/tagsSlice";
import { CLICK_NEW_AD, INPUT_IMAGES, SUBMIT_NEW_AD } from "../../config/events";
import { ACTION_CLICK, ACTION_INPUT, ACTION_SUBMIT } from "../../config/actions";
import {
  getVerifiedPhone,
  selectLoggedUser,
} from "../../features/session/sessionSlice";

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  makeStyles,
  setRef,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import Compressor from 'compressorjs';
import { toast } from "react-toastify";
import { setAmount, setClientRef, setImg, setPrice, setSeotag, setTitle } from "../../a_components/features/addOnServices";
import { setBoostAdValues, setBundleValues, setFeaturedAdValues, setIsMember, setJumpAdValues, setQuickSellValues, setReferenceMember, setUhmId, setVoucherFreeAmount } from "../../a_components/AddOn/AddOnValues";

import LineProgress from "../../components/loader/LineProgress";
import { apartment_rent, apartment_sale, audio_speakers, bikes, cameras, cars, commercial_rent, commercial_sale, computers, daily, house_rent, house_sale, land_rent, land_sale, mobiles, mobile_parts, monthly, other_electronics, room_rent, tab, tv } from "../../constants/categories";
import PaymentOverview from "../../a_components/pages/payment/PaymentOverview";
import { CURRENT_BUILD } from "../../config/build_config";
import LinearProgressBar, { setProgr, getProgr, getFinish, getIsComplete } from "../../components/loader/LinearProgress";
import AddPostMobile from "./AddPostMobile";
import AddImagesS3 from "../../components/AddPost/Images/AddImagesS3";
import { selectLanguage } from "../../features/language/language_slice";
import { Language } from "../../language/Language";
import { imageUploadError } from "../../components/loader/LinearProgress";
import { analytics } from "../..";
import { logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { generatePresignedS3Url } from "../../config/urls";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordian: {
      "& .MuiAccordionDetails-root": {
        flexDirection: "column",
      },
      "& .MuiAccordionSummary-root": {
        borderBottom: "1px solid #DDDDDD",
        marginBottom: "20px",
        "& .MuiAccordion-root.Mui-disabled": {
          backgroundColor: "#DDD",
        },
      },
      boxShadow: "0px 0px 3px #00000026",
    },
    header: {
      color: "#000",
      fontSize: "16px",
      fontWeight: 600,
    },
    buttonDisbale: {
      backgroundColor: "black"
    }
  })
);


// var dispatch: any;
var filldetailsopen = false;
var imagesSelected = false;
var catSelected = false;
var uploading = false;


const AddPost: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stepAddpost = useSelector(selectCurrentStep);
  const navigate = useNavigate();
  const [interacted, setInteracted] = useState(false);
  const [loader, setShowLoader] = useState(false);
  const [showAlertSlose, setShowAlertClose] = useState(false);
  const newPost = useSelector(selectNewPosts);
  const mainBtn: any = useRef();

  const user = useSelector(selectLoggedUser);
  const [hideFooter, setHideFooter] = useState(false);
  const theme = useTheme();

  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  // const [changePhone, setChangePhone] = useState(false);
  const [changePhone, setChangePhone] = useState(false);


  const savedCat = useSelector(selectCategories);
  const selectedCat = useSelector(selectedCategories);
  const [subCategories, setSubCategories] = useState([]);
  // const [catSelected, setcatSelected] = useState(false);
  const [confirmImageUplaod, setconfirmImageUplaod] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [phone, setPhone] = useState("");
  const [urlArray, setUrlArray] = useState<string[]>([]);
  const imgBtn: any = useRef();
  const formBtn: any = useRef();
  const [detailsFilled, setdetailsFilled] = useState(false);
  const [finish, setFinish] = useState(false);
  const [showToast, setShowToast] = useState({ message: "", show: false });

  // const [imagesSelected, setimagesSelected] = useState(false);
  // const [filldetailsopen, setfilldetailsopen] = useState(false);

  // const PostNAD = () => {

  //   dispatch(pushPost(newPost, () => { onPostingCompleted("Your Ad is Under Review"); }, () => onPostingCompleted("Error occured"), () => setShowLoader(false)));
  //   events.logEvent(SUBMIT_NEW_AD, { action: ACTION_SUBMIT, platform: "DESKTOP", });
  // }
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));


  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(setCurrentLocation(NAV_ADD_POST));
    logEvent(analytics, CLICK_NEW_AD, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
    dispatch(
      getVerifiedPhone((phones: any) => {
        setPhone(phones?.phones[0] != "" ? phones.phones[0] : "");
      })
    );
    return () => {
      clearAndClose();
    };
  }, []);

  useEffect(() => {

    if (newPost.specs.length > 0) {
      // setfilldetailsopen(false);
      filldetailsopen = false;

    }
  }, [newPost.specs]);

  useEffect(() => {
    // console.log(urlArray.length);
    // console.log(newPost.rawImages.length);

    if (newPost.images.length === newPost.rawImages.length) {
      setProgr(100)
      // setIsComplete(true);
      setUploading(false)
      handleClick();
    }

  }, [
    newPost.rawImages.length
  ])


  function isSaveCatNotNull() {
    if (savedCat.length > 0) {
      return true;
    }

    return false;
  }

  useEffect(() => {

    setSubCategories(
      savedCat
        .filter((l1: any) => l1.name == selectedCat.level1)
        .flatMap((sub: any) => sub.sub_categories)
    );
  }, [selectedCat.level1]);

  const renderSwitch = (step: any, ref: any) => {
    switch (step) {
      case 1:
        // { (isSaveCatNotNull() ? console.log("not null") : console.log("null")) }
        return <SelectCategory isSubNull={(isSaveCatNotNull() ? "Okay" : "Wait")} />;
      case 2:
        // { (isSaveCatNotNull() ? console.log("not null") : console.log("null")) }
        return (isSaveCatNotNull() ? <SubCategory subCategories={subCategories} /> : <></>);
      case 3:
        // return <AddImages ref={ref} setInteracted={setInteracted} />;
        return (
          <FillDetails
            ref={ref}
            setInteracted={setInteracted}
            setHideFooter={setHideFooter}
          />
        );

      case 4:
        return <AddImagesS3 ref={ref} setInteracted={setInteracted} setUploading={setUploading} handleClick={handleClick} />;
      case 5:
        return (
          <AddContact ref={ref} setInteracted={setInteracted} phone={phone} />
        );

    }
  };
  const handleBack = () => {
    if (stepAddpost == 4) {
      dispatch(clearImages([]));
    }
    dispatch(setCurrentStep(stepAddpost - 1));
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClick = () => {
    if (user.skip_phone_verify && user.phone == "" && phone == "") {
      setChangePhone(true);
      return;
    }

    if (stepAddpost == 5) {
      // if (newPost.rawImages.length > 0) {

        // (getProgr() >= 100 ?
        dispatch(
          pushPost(
            newPost,
            (jsonData: any) => {

              onPostingCompleted("Your Ad is Under Review", jsonData);
            }
            ,
            () => onPostingCompleted("Error occured", {}),
            () => setShowLoader(false)
          ))
        // ) : st())

      // } else {
      //   alert("Sorry!, please re add your images and try again!");
      //   // dispatch(imageUploadError());
      //   imageUploadError();
      //   dispatch(clearImages([]));
      //   setCatSelected(true);
      //   dispatch(setCurrentStep(4));
      //   setimagesSelectedd(false);
      //   setfilldetailsopenn(false);
      // }

      logEvent(analytics, SUBMIT_NEW_AD, { action: ACTION_SUBMIT, platform: "DESKTOP" });

      return;
    }
    mainBtn.current?.trigger();
  };


  function getExtensionFromBase64(mimeType: string): string {
    // console.log(base64);

    // // Ensure the input is a string
    // if (typeof base64 !== "string") {
    //   throw new Error("Input must be a Base64 string");
    // }

    // // Extract the MIME type from the Base64 string
    // const match = base64.match(/^data:(.*?);base64,/);
    // if (!match) {
    //   throw new Error("Invalid Base64 string");
    // }
    // const mimeType = match[1];

    // Map MIME type to file extension
    const mimeToExtensionMap: Record<string, string> = {
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/gif": "gif",
      "image/webp": "webp",
      "image/svg+xml": "svg",
      "image/bmp": "bmp",
      "image/tiff": "tiff",
    };

    // Return the file extension or "unknown" if not found
    return mimeToExtensionMap[mimeType] || "unknown";
  }


  const BUCKET_NAME = 'saruwata';

  const uploadToS3 = async (image: any, index: number, folder: number) => {

    const extension = getExtensionFromBase64(image.type);
    const response = await axios.get(
      `${generatePresignedS3Url}?bucketName=${BUCKET_NAME}&key=${CURRENT_BUILD}/posts/${folder}/${folder}_${index}.${extension}`
    );

    const presignedUrl = response.data;


    axios.put(presignedUrl, image, {
      headers: {
        "Content-Type": image.type,
        skipAuth: true
      },
    })
      .then((d) => {

        if (d.status == 200) {

          const fileName = presignedUrl.split("?")[0];

          dispatch(addNewPostRawImages(fileName));
          // urlArray.push(fileName);
          // setUrlArray([...urlArray, fileName]);
          setUrlArray((prevItems) => [...prevItems, fileName]);



          // if (newPost.images.length === newPost.rawImages.length) {
          //   console.log("---------------------------------");
            
          //   setProgr(100)
          //   // setIsComplete(true);
          //   setUploading(false)
          //   handleClick();
          // }
        }
      })
      .catch(e => {
        console.log(e);

      });



  }
  function getMimeType(base64: string) {
    const match = base64.match(/^data:(.*?);base64,/);
    return match ? match[1] : null; // Returns the MIME type or null if not found
  }
  function base64ToBlob(base64: any, mimeType: any) {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }


  const handleCompressedUpload = (image: any, index: number, folder: number) => {

    const imageBlob = base64ToBlob(image, getMimeType(image));

    // Use Compressor
    new Compressor(imageBlob, {
      quality: 0.7,
      success: (compressedResult) => {

        uploadToS3(compressedResult, index, folder);
      },
      error: (err) => {
        console.error("Compression error:", err);
      },
    });



    // var quality = 1;
    // if (image.size >= 10485760) {
    //   quality = 0.5;
    // } else if (image.size > 1572864) {

    //   quality = 0.6;
    // } else {

    //   quality = 0.7;
    // }

    // const imageBlob = base64ToBlob(base64Image, mimeType);

    // new Compressor(image, {
    //   quality: quality, // 0.6 can also be used, but its not recommended to go below.
    //   success: (compressedResult) => {

    //     uploadToS3(compressedResult, index, folder);
    //     // setCompressedFile(compressedResult)
    //   },
    // });
  };


  async function manageImagesAndUpload() {

    const ts = new Date().getTime();

    setUploading(true)

    for (let index = 0; index < newPost.images.length; index++) {

      let image = newPost.images[index];
      handleCompressedUpload(image, index, ts);
    }
    dispatch(setImgFolder(ts));
  }


  const checkSelectedImages = () => {

    manageImagesAndUpload();
    logEvent(analytics, INPUT_IMAGES, { action: ACTION_INPUT, platform: 'DESKTOP' });

  };












  function st() {
    alert("Please remain until the images upload");


  }




  // const handleClick = () => {
  //   if (user.skip_phone_verify && user.phone == "" && phone == "") {
  //     setChangePhone(true);
  //     return;
  //   }

  //   if (stepAddpost == 5) {

  //     dispatch(
  //       pushPostWithoutPayment(
  //         newPost,
  //         () => {
  //           onPostingCompletedWithoutPayment("Your Ad is Under Review");
  //         }
  //         ,
  //         (data: any) => { onPostingCompletedWithoutPayment("Error occured"); console.log(data) },
  //         () => setShowLoader(false)
  //       )
  //     );
  //     events.logEvent(SUBMIT_NEW_AD, {
  //       action: ACTION_SUBMIT,
  //       platform: "DESKTOP",
  //     });

  //     return;
  //   }
  //   mainBtn.current?.trigger();
  // };

  const clearAndClose = () => {
    dispatch(clearNewPost(newPost));
    dispatch(setCurrentStep(1));
    // setimagesSelected(false);
    imagesSelected = false;
    setInteracted(false);
    setShowLoader(false);
    setCatSelected(false);
    dispatch(setSelectedCategories({}));
    setdetailsFilled(false);
    // setfilldetailsopen(false);
    filldetailsopen = false;
    setShowAlertClose(false);
    setProgr(0);

  };

  const onPostingCompleted = (msg: string, jsonData: any) => {
    // history.replace("/home");

    if(jsonData.data!=""){
      
      clearAndClose();
      dispatch(setSeotag(jsonData.data.seoTag));
      dispatch(setTitle(jsonData.data.title));
      dispatch(setPrice(jsonData.data.price));
      dispatch(setImg(jsonData.data.img));
      dispatch(setAmount(jsonData.data.amount));
      dispatch(setClientRef(jsonData.data.ref));
      dispatch(setBundleValues(jsonData.data.addons.bundle));
  
      // console.log(jsonData.data);
  
      dispatch(setJumpAdValues(jsonData.data.addons.jump));
      dispatch(setBoostAdValues(jsonData.data.addons.boost));
      dispatch(setQuickSellValues(jsonData.data.addons.quik));
      dispatch(setFeaturedAdValues(jsonData.data.addons.feat));
  
      dispatch(setVoucherFreeAmount(jsonData.data.voucherFreeAmount));
      dispatch(setReferenceMember(jsonData.data.reference));
      dispatch(setUhmId(jsonData.data.uhmId));
      dispatch(setIsMember(jsonData.data.member));
  
  
      navigate(`/payment/${jsonData.data.seoTag}`);
  
      // dispatch(getAllAvailablePosts(() => { }, true, 0, 0));
      // history.go(-1);
  
      if (msg === 'Error occured') {
  
        toast.error(msg, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
  
      } else {
  
        toast.success(msg, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
    

  };
  const onPostingCompletedWithoutPayment = (msg: string) => {


    dispatch(getAllAvailablePosts(() => { }, true, 0, 0));
    clearAndClose();
    navigate("/home");
    // dispatch(clearAllPosts([]));
    // history.go(-1);

    if (msg === 'Error occured') {

      toast.error(msg, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

    } else {

      toast.success(msg, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }

  };

  const handleNoNeedPhoneVerify = () => {
    setChangePhone(false);
  };


  const handleImageUpload = () => {

    // setconfirmImageUplaod(false);

    // imgBtn.current?.trigger();
    checkSelectedImages()

  }
  const handleImageAction = () => {

    dispatch(setCurrentStep(5));
  }



  const handleFilledDetails = () => {
    const ret = formBtn.current?.trigger();
    if (ret) {
      dispatch(setCurrentStep(4));
      setdetailsFilled(true);
    }
  };

  function isMandatoryValuesAdded() {
    var c = getMandatoryDetails();
    if (c[0] === cars) {
      var val = c[1] as any;
      if (val.Mileage === '') {
        return false;
      }
    }
  }

  return (
    <div>

      <div>
        {/* <Prompt
          when={!!selectedCat.level1}
          message={() => `You will loose all unsaved data, Are you sure ?`}
        /> */}

        {/* <UserContext.Consumer>
          {(user: any) =>
            user != null &&
              user.skip_phone_verify &&
              user.phone == "" &&
              phone == "" ? (
              <div
                style={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  zIndex: 9,
                }}
                onClick={() => setChangePhone(true)}
              // onClick={() => setChangePhone(false)}
              ></div>
            ) : null
          }
        </UserContext.Consumer> */}

        {
          user != null &&
            user.skip_phone_verify &&
            user.phone == "" &&
            phone == "" ? (
            <div
              style={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                zIndex: 9,
              }}
              onClick={() => setChangePhone(true)}
            // onClick={() => setChangePhone(false)}
            ></div>
          ) : null
        }


        {/* <Hidden xsDown={true} smDown={true}> */}


          <div style={{ height: "10px" }}></div>
          <div style={{ color: "#000000", fontSize: (getLanguage() == 'english' ? '18px' : '15px'), fontWeight: 600 }}>
            {/* Post Ad */}
            {lan.POST_AD}
          </div>
          <div style={{ backgroundColor: "#EBEEF1", borderRadius: "6px" }}>
            <div style={{ padding: "5px" }}>
              <Accordion
                className={classes.accordian}
                TransitionProps={{ unmountOnExit: true }}
                // disabled={
                //   selectedCat.level1 && selectedCat.level2 && catSelected
                //     ? true
                //     : false
                // }
                // expanded={
                //   selectedCat.level1 && selectedCat.level2 && catSelected
                //     ? false
                //     : true
                // }

                expanded={
                  stepAddpost == 1
                    ? true
                    : false
                }
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon onClick={() => {
                    // setCatSelected(false);
                    dispatch(setCurrentStep(1));
                  }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"

                >
                  <Typography className={classes.header} style={{ fontSize: (getLanguage() == 'english' ? '15px' : '13px') }}>
                    {/* Select the appropriate category to post your ad */}
                    {lan.SELECT_APPROPRIATE_CATEGORY}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {renderSwitch(1, "")}
                  {renderSwitch(2, "")}
                </AccordionDetails>
                {/* <AccordionActions
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
            
                <Button
                  size="small"
                  onClick={() => {
                    setcatSelected(true);
                    dispatch(setCurrentStep(3));
                  }}
                  variant="contained"
                  id="continue-btn"
                  disabled={
                    selectedCat.level1 && selectedCat.level2 ? false : true
                  }
                >
                  <span id="continue-btn-txt">Continue</span>
                </Button>
              </AccordionActions> */}
              </Accordion>


              <Accordion
                className={classes.accordian}
                TransitionProps={{ unmountOnExit: true }}
                // expanded={detailsFilled ? false : catSelected}
                // disabled={detailsFilled}
                expanded={
                  stepAddpost == 3
                    ? true
                    : false
                }
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon onClick={() => {
                    if (selectedCat.level1 && selectedCat.level2 && catSelected) {
                      dispatch(setCurrentStep(3));
                      setdetailsFilled(false)
                    }
                  }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.header} style={{ fontSize: (getLanguage() == 'english' ? '15px' : '13px') }}>
                    {/* Fill in the details */}
                    {lan.FILL_IN_THE_DETAILS}
                  </Typography>

                </AccordionSummary>

                <AccordionDetails>{renderSwitch(3, formBtn)}</AccordionDetails>
                <AccordionActions
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >

                  <Button
                    size="small"
                    onClick={handleFilledDetails}
                    variant="contained"
                    id="continue-btn"

                  >
                    <span id="continue-btn-txt">
                      {/* Continue */}
                      {lan.CONTINUE}
                    </span>
                  </Button>
                  {/* {detailsFilled ? (
                    <>
                      <Alert
                        severity="success"
                        style={{ marginBottom: "20px", marginLeft: "20px", fontSize: (getLanguage() == 'english' ? '15px' : '13px') }}
                      >
                      
                        {lan.DETAILS_FILLED}
                      </Alert>
                    </>
                  ) : (
                    <>
                      <Button
                        size="small"
                        onClick={handleFilledDetails}
                        variant="contained"
                        id="continue-btn"

                      >
                        <span id="continue-btn-txt">
                       
                          {lan.CONTINUE}
                        </span>
                      </Button>
                      
                    </>

                  )} */}
                </AccordionActions>
              </Accordion>


              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                className={classes.accordian}
                // expanded={imagesSelected ? false : catSelected}
                // disabled={imagesSelected}

                // expanded={filldetailsopen ? true : false}
                // disabled={imagesSelected}
                expanded={
                  detailsFilled && stepAddpost == 4
                    ? true
                    : false
                }
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon onClick={() => {
                    if (filldetailsopen) {
                      dispatch(setCurrentStep(4));
                    }
                  }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.header} style={{ fontSize: (getLanguage() == 'english' ? '15px' : '13px') }}>
                    {/* Check your photos and confirm */}
                    {lan.CHECK_YOUR_PHOTOS_AND_CONFIRM}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{renderSwitch(4, imgBtn)}</AccordionDetails>
                <AccordionActions
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >

                  <Button
                    size="small"
                    onClick={() => { handleImageAction() }}
                    variant="contained"
                    id="continue-btn"
                    disabled={uploading}
                    style={{ backgroundColor: (uploading ? "#b0c0d1" : "#0080ff") }}

                  >
                    <span id="continue-btn-txt">
                      {/* Continue */}
                      {lan.CONTINUE}
                    </span>
                  </Button>


                  <div
                    style={{
                      color: "#5A5A5A",
                      fontSize: "12px",
                      marginBottom: "20px",
                      marginLeft: "32px",
                    }}
                  >
                    {/* Images must be JPG or PNG format (max 15 MB).<br></br>  Do not
                    upload images with watermarks. */}

                    {lan.IMAGES_FORMAT}<br></br>{lan.NO_WATERMARS}
                  </div>
                  {/* <LineProgress></LineProgress> */}


                </AccordionActions>

              </Accordion>

              <Accordion
                className={classes.accordian}
                TransitionProps={{ unmountOnExit: true }}
                expanded={stepAddpost == 5}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon onClick={() => {
                    if (imagesSelected) {
                      dispatch(setCurrentStep(5));
                    }
                  }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.header} style={{ fontSize: (getLanguage() == 'english' ? '15px' : '13px') }}>
                    {/* Contact details */}
                    {lan.CONTACT_DETAILES}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{renderSwitch(5, mainBtn)}</AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#EBEEF1",
              borderRadius: "6px",
              marginTop: "26px",
            }}
          >
            <div style={{ minHeight: "168px", padding: "5px", width: "100%" }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding:20
                }}
              >
                <div>
                  {uploading ? <>
                  <div style={{position:'relative',display:'flex',gap:10,justifyContent:'center',alignItems:'center'}}>
                    <CircularProgress  style={{ width: "50px", height: "50px" }} />
                    <span style={{ fontSize: "16px",marginLeft:10 }}>Uploading...</span>
                  </div>
                  </>
                    :
                    <>
                      <Button

                        onClick={handleImageUpload}
                        // onClick={handleClick}
                        // onClick={PostNAD}
                        variant="contained"
                        id="adpost-btn"
                        disabled={!interacted}
                        style={{ backgroundColor: ((!interacted) ? "#00000033" : "#0080FF"), fontSize: (getLanguage() == 'english' ? '15px' : '13px') }}
                      >
                        {/* <span id="adpost-btn-txt">Post AD</span> */}
                        <span id="adpost-btn-txt">
                          {/* Continue */}
                          {lan.CONTINUE}
                        </span>
                      </Button>

                      <Button
                        variant="outlined"
                        id="clearall-btn"
                        onClick={() => {
                          clearAndClose();
                          setUploading(false);
                        }}
                      >
                        <span id="clearall-btn-txt" style={{ fontSize: (getLanguage() == 'english' ? '15px' : '13px') }}>
                          {/* Clear All */}
                          {lan.CLEAR_ALL}
                        </span>
                      </Button>
                    </>}
                </div>
                <div
                  style={{
                    color: "#5A5A5A",
                    fontSize: "12px",
                    textAlign: "center",
                    marginTop: "23px",
                  }}
                >
                  {/* By clicking on Post Ad, you accept the Terms of Use, confirm
                  that you will abide by the Safety Tips,
                  <br />
                  and declare that this posting does not include any Prohibited
                  Items. */}
                  {lan.ACCEPT_TERMS}
                </div>
              </div>
            </div>
          </div>


          <Dialog
            open={showAlertSlose}
            onClose={() => setShowAlertClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you want to exit from Ad post?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your Ad post will Discard
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.history.pushState(null, "", document.URL);
                  setShowAlertClose(false);

                }}
                color="primary"
              >
                cancel
              </Button>
              <Button
                onClick={() => {
                  clearAndClose();
                  navigate(-1);
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {/* <IonAlert
          isOpen={showAlertSlose}
          onDidDismiss={() => setShowAlertClose(false)}
          header={"Do you want to exit from Ad post?"}
          message={"Your Ad post will Discard"}
          buttons={[
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
               window.history.pushState(null, "", document.URL);
                setShowAlertClose(false);
              },
            },
            {
              text: "Yes",
              handler: () => {
                clearAndClose();
              },
            },
          ]}
        /> */}
          <Dialog
            open={showAlertSlose}
            onClose={() => setShowAlertClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you want to exit from Ad post?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your Ad post will Discard
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  window.history.pushState(null, "", document.URL);
                  setShowAlertClose(false);
                }}
                color="primary"
              >
                cancel
              </Button>
              <Button
                onClick={() => {
                  clearAndClose();
                  navigate(-1);
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <IonAlert
            isOpen={changePhone}
            onDidDismiss={handleNoNeedPhoneVerify}
            header={"Phone number required"}
            message={"Add phone number now ?"}
            buttons={[
              {
                text: "No",
                role: "cancel",
                cssClass: "secondary",
                handler: handleNoNeedPhoneVerify,
              },
              {
                text: "Add Now",
                handler: () => navigate("/profile"),
              },
            ]}
          />
          <Dialog
            open={confirmImageUplaod}
            onClose={() => { setconfirmImageUplaod(false); setUploading(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm image upload"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {/* The images you selected cannot be changed later..! */}
                Do you want to upload selected images
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setconfirmImageUplaod(false);
                  setUploading(false);


                }}
                color="secondary"
              >
                close
              </Button>
              <Button onClick={handleImageAction} color="primary" autoFocus>
                ok
              </Button>
              {/* <Button onClick={handleImageUpload} color="primary" autoFocus>
                ok
              </Button> */}
            </DialogActions>
          </Dialog>
        {/* </Hidden> */}
        {/* <Hidden lgUp={true} mdUp={true}> */}
          {/* <AddPostMobile /> */}
        {/* </Hidden> */}
      </div>
    </div>
  );



};
export function setCatSelected(param: boolean) {
  catSelected = param;

}

export const setfilldetailsopenn = (bool: boolean) => {


  filldetailsopen = bool;
}
export const setimagesSelectedd = (bool: boolean) => {
  // dispatch(setimagesSelected(true));
  imagesSelected = bool;
}


export default AddPost;
